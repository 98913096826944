import { trackEvent } from '@studio/lib/heap';
import { Tours } from './tours';

type TourEvents = {
  onboarding_tour_shown: string;
  onboarding_tour_dismissed: string;
  onboarding_tour_completed: string;
};

export const trackTourEvent = (
  event: keyof TourEvents,
  tourName: keyof Tours,
  data: Record<string, unknown> = {}
) => {
  trackEvent(event, { tourName, ...data });
};
