import { PageParams } from '@studio/stores';
import { PARAM_KEYS } from '../constants';

export type ProjectsParams = PageParams<typeof PARAM_KEYS>;

export enum ProjectQueryParams {
  BRAINSTORM = 'brainstorm',
  ELEMENT = 'element',
  PANEL_VISIBILITY = 'expanded',
  PANEL_CONTROL = 'panel_control',
  PANELS = 'panels',
  PANEL_SECTIONS = 'sections',
  NEW_PROJECT_ID = 'newProjectId',
  PANEL = 'panel',
  TOOLBAR = 'tools',
}
