import { useQuery } from '@tanstack/react-query';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { USE_QUERY_KEYS } from '@studio/hooks';
import { PowerKeyword } from '@lib/types';
import { fetchPowerKeywords } from '../requests';

export function useFetchPowerKeywordsQuery(input: {
  id: string;
  text?: string;
  enabled: boolean;
  initialData?: PowerKeyword[];
}) {
  const channelUcid = useActiveChannelUcid();

  const queryInput = {
    channelUcid,
    text: input.text,
  };

  return useQuery({
    queryKey: [
      USE_QUERY_KEYS.FETCH_POWER_KEYWORDS_KEY,
      channelUcid,
      input.id,
      input.text,
    ],
    queryFn: () => fetchPowerKeywords(queryInput),
    enabled: Boolean(channelUcid) && input.enabled,
    initialData: input.initialData,
  });
}
