/**
 * Removes non-numeric characters from a given string. Processes numeric strings
 * where commas are used as thousand separators or other characters are present and strips out any existing commas or non-numeric characters
 * from the input string.
 *
 * @param {string} numericString - The string from which non-numeric characters will be removed.
 *                                 Expected to be a numeric string.
 * @returns {string | undefined | null} - A string with all commas and non-numeric characters removed.
 *                     If the input is falsy (e.g., null, undefined, ''), an empty string is returned.
 */
export const removeCharactersFromString = (
  numericString: string | undefined | null
): string => {
  if (!numericString) {
    return '';
  }
  /* strip out any non-numerical characters */
  return numericString.replace(/[^0-9.]/g, '');
};

/**
 * Converts a number (or a numeric string) into a string with commas added for readability.
 * Commas are inserted as thousand separators in the appropriate places to enhance
 * the readability of large numbers. For instance, 1000 becomes '1,000'.
 *
 * TODO Hook replace `EN-us` below with a users locale once we internationalize
 *
 * @param {number | string | undefined | null} number - The number (or a numeric string) to which commas will be applied.
 * @returns {string} - A string representation of the number with commas inserted.
 *                     If the input is falsy or cannot be converted to a valid number,
 *                     the function returns the input converted to a string without any modification.
 */
export const applyCommasToNumbers = (
  value: number | string | undefined | null
) => {
  if (value === '' || value === null || value === undefined) {
    return '';
  }
  return Number(value).toLocaleString('EN-us');
};

/**
 * Render a string representation of the input value.
 * @param {string | number | undefined | null} value - The value to render as a string.
 * @returns {string} The rendered string representation of the input value. If the input value is undefined or null, an empty string is returned.
 */
export const renderString = (
  value: string | number | undefined | null
): string => {
  if (value === undefined || value === null) {
    return '';
  }
  return value.toString();
};

/**
 * Formats a given input into a string representation of a whole number with specific formatting rules.
 * If the input is a string, number, or undefined:
 * - Returns a string representation of the input as a whole number.
 * - For numbers between 10,000 and 1 million, converts to 'k' notation.
 * - For numbers between 1 million and 1 billion, converts to 'm' notation.
 * - For numbers 1 billion and above, converts to 'b' notation.
 * - Adds a comma for numbers with more than three digits less than 10,000.
 * - Returns an empty string for non-numeric inputs.
 * If the resulting formatted number has a decimal point followed only by zeros, those zeros are removed.
 *
 * @param {string | number | undefined | null} input The input value to format.
 * @return {string} The formatted number as a string, or an empty string for non-numeric inputs.
 */
export const abbreviateNumber = (
  input: string | number | bigint | null | undefined
): string => {
  if (input === undefined || input === null) return '';

  const inputStr = input.toString();

  if (!/^\d+(\.\d+)?$/.test(inputStr)) {
    return '';
  }

  const integerPart = inputStr.split('.')[0];
  const num = parseInt(integerPart);

  const formatDecimal = (
    value: number,
    divisor: number,
    suffix: string
  ): string => {
    const result = value / divisor;
    return result % 1 === 0
      ? `${result}${suffix}`
      : `${result.toFixed(1)}${suffix}`;
  };

  if (num >= 1_000_000_000) {
    return formatDecimal(num, 1_000_000_000, 'B');
  }
  if (num >= 1_000_000) {
    return formatDecimal(num, 1_000_000, 'M');
  }
  if (num >= 1_000) {
    return formatDecimal(num, 1_000, 'K');
  }
  return integerPart;
};
