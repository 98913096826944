import { trackEvent } from '@studio/lib/heap';
import { ProjectElementType } from '@lib/types';
import * as EVENTS from '../../heap.constants';

export const useProjectPackageAnalytics = (id: string) => {
  const track = (
    label: string,
    properties?: Record<string, string | undefined>
  ) => {
    trackEvent(label, {
      videoProject: id,
      ...properties,
    });
  };

  const onBrainstormEvent = (
    properties?: Record<string, string | undefined>
  ) => {
    track(EVENTS.PROJECTS_BRAINSTORM_CLICK, properties);
  };

  const onDeleteProject = () => {
    track(EVENTS.PROJECTS_DELETE_PROJECT_CLICK);
  };

  const onProjectDuplicate = () => {
    track(EVENTS.PROJECTS_DUPLICATE_PROJECT_CLICK);
  };

  const onCreateElement = (element: ProjectElementType) => {
    track(EVENTS.PROJECTS_ADD_NEW_CLICK_ON_SIDE, {
      element,
    });
  };

  const onDeleteElement = (element: ProjectElementType) => {
    track(EVENTS.PROJECTS_DELETE_CLICK_ON_SIDE, {
      element,
    });
  };

  const onCopyElement = (element: ProjectElementType) => {
    track(EVENTS.PROJECTS_COPY_CLICK_ON_SIDE, {
      element,
    });
  };

  const onUploadClick = () => {
    track(EVENTS.PROJECTS_THUMBNAIL_UPLOAD_CLICK);
  };

  const onUploadSuccess = () => {
    track(EVENTS.PROJECTS_THUMBNAIL_UPLOAD_SUCCESS);
  };

  const onThumbnailDownload = () => {
    track(EVENTS.PROJECTS_DOWNLOAD_CLICK_ON_SIDE);
  };

  const onYoutubePreviewDialogOpen = () => {
    track(EVENTS.PROJECTS_PANEL_YOUTUBE_PREVIEW_DIALOG_OPEN);
  };

  const onYoutubePreviewDialogClose = () => {
    track(EVENTS.PROJECTS_PANEL_YOUTUBE_PREVIEW_DIALOG_CLOSE);
  };

  const onYoutubePreviewThemeChange = (theme: string) => {
    track(EVENTS.PROJECTS_PANEL_YOUTUBE_PREVIEW_THEME_CHANGE, {
      theme,
    });
  };

  const onYoutubePreviewDeviceTypeChange = (deviceType: string) => {
    track(EVENTS.PROJECTS_PANEL_YOUTUBE_PREVIEW_DEVICE_TYPE_CHANGE, {
      deviceType,
    });
  };

  const onYoutubePreviewSectionChange = (section: string) => {
    track(EVENTS.PROJECTS_PANEL_YOUTUBE_PREVIEW_SECTION_CHANGE, {
      section,
    });
  };

  return {
    onBrainstormEvent,
    onDeleteProject,
    onProjectDuplicate,
    onCreateElement,
    onDeleteElement,
    onCopyElement,
    onUploadClick,
    onUploadSuccess,
    onThumbnailDownload,
    onYoutubePreviewDialogOpen,
    onYoutubePreviewDialogClose,
    onYoutubePreviewThemeChange,
    onYoutubePreviewDeviceTypeChange,
    onYoutubePreviewSectionChange,
  };
};
