import {
  ForwardedRef,
  forwardRef,
  type ButtonHTMLAttributes,
  type ReactNode,
} from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { IconButton, Icons } from '../../';
import * as Styles from './brainstorm-button.css';

export type BrainstormButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: ReactNode;
  isHovered?: boolean;
  isInteractable?: boolean;
  menuOpen?: boolean;
  onCloseMenu?: () => void;
};

/**
 * Brainstorm Button
 */
export const BrainstormButton = forwardRef(
  (props: BrainstormButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      className,
      isHovered,
      isInteractable = true,
      menuOpen,
      onCloseMenu,
      ...rest
    } = props;
    const { t } = useTranslation();

    const handleOnClose = () => {
      if (onCloseMenu) {
        onCloseMenu();
      }
    };

    if (menuOpen) {
      return (
        <IconButton
          ref={ref}
          fill="solid"
          radii="pill"
          size="xs"
          className={`menu-open ${Styles.closeButton}`}
          onClick={handleOnClose}
          icon={<Icons.CloseIcon aria-label={t('Close brainstorm menu')} />}
        />
      );
    }

    return (
      <button
        ref={ref}
        className={clsx(
          'brainstorm-button',
          Styles.base,
          {
            [Styles.hovered]: isInteractable && isHovered,
            [Styles.interaction]: isInteractable,
          },
          className
        )}
        {...rest}
      >
        <Icons.AIIcon aria-label={t('Brainstorm')} />
        {isInteractable ? (
          <span className={Styles.content}>{children}</span>
        ) : null}
      </button>
    );
  }
);
