import type { ReactElement, ReactNode } from 'react';
import {
  DateRangeFilterInput,
  MadeForKidsFilterToggles,
  OutliersFilterInput,
  SubscribersFilterInput,
  VideoDurationFilterInput,
  VideoViewsFilterInput,
} from './components/filters/inputs';
import { FILTER_PARAMS, SORT_ORDER } from './constants';

type FilterParamValues = (typeof FILTER_PARAMS)[keyof typeof FILTER_PARAMS];
type SortParamValues = (typeof SORT_ORDER)[keyof typeof SORT_ORDER];

export const OUTLIERS_FILTERS = {
  SOURCE: 'source',
  OUTLIER_INDEX: 'outlier_index',
  UPLOAD_DATE: 'upload_date',
  SUBSCRIBER_COUNT: 'subscriber_count',
  VIDEO_VIEWS: 'video_views',
  VIDEO_LENGTH: 'video_length',
  MADE_FOR_KIDS: 'made_for_kids',
} as const;

export type FilterPreset = {
  /**
   * Unique id per filter preset, can be any string.
   */
  id: string;
  /**
   * Human readable label for each filter preset
   */
  label: string;
  /**
   * Controls which filter in the accordion dropdown should be toggled ON when
   * a given filter preset is selected.
   */
  filters: (typeof OUTLIERS_FILTERS)[keyof typeof OUTLIERS_FILTERS][];
  /**
   * Icon to display in the filter preset chip
   */
  icon: ReactElement;
  /**
   * Set the params to filter Outliers by
   */
  params: Partial<Record<FilterParamValues, string>>;
  /**
   * How to sort the given filter preset's results
   */
  sort: SortParamValues;
};

export type OutliersMenuFilterData = {
  label: string;
  input: ReactNode;
  params: string[];
  value: string;
};

export const filters: OutliersMenuFilterData[] = [
  {
    label: 'Outlier index',
    value: OUTLIERS_FILTERS.OUTLIER_INDEX,
    input: <OutliersFilterInput />,
    params: [
      FILTER_PARAMS.PERFORMANCE_INDEX_GTE,
      FILTER_PARAMS.PERFORMANCE_INDEX_LTE,
    ],
  },
  {
    label: 'Upload date',
    value: OUTLIERS_FILTERS.UPLOAD_DATE,
    input: <DateRangeFilterInput />,
    params: [FILTER_PARAMS.UPLOADED_END, FILTER_PARAMS.UPLOADED_START],
  },
  {
    label: 'Subscriber count',
    value: OUTLIERS_FILTERS.SUBSCRIBER_COUNT,
    input: <SubscribersFilterInput />,
    params: [FILTER_PARAMS.SUBSCRIBERS_GTE, FILTER_PARAMS.SUBSCRIBERS_LTE],
  },
  {
    label: 'Video views',
    value: OUTLIERS_FILTERS.VIDEO_VIEWS,
    input: <VideoViewsFilterInput />,
    params: [FILTER_PARAMS.VIEWS_GTE, FILTER_PARAMS.VIEWS_LTE],
  },
  {
    label: 'Video length',
    value: OUTLIERS_FILTERS.VIDEO_LENGTH,
    input: <VideoDurationFilterInput />,
    params: [FILTER_PARAMS.DURATION_GTE, FILTER_PARAMS.DURATION_LTE],
  },
  {
    label: 'Made for kids',
    value: OUTLIERS_FILTERS.MADE_FOR_KIDS,
    input: <MadeForKidsFilterToggles />,
    params: [FILTER_PARAMS.MADE_FOR_KIDS],
  },
];
