import { ComponentProps } from 'react';
import { components } from '@reactour/tour';
import { Flex } from '@lib/ui';
import * as Styles from './styles.css';

export function Badge({ children }: ComponentProps<typeof components.Badge>) {
  return (
    <Flex alignItems="center" justifyContent="center" className={Styles.Badge}>
      {children}
    </Flex>
  );
}
