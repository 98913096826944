import { RumEvent } from '@datadog/browser-rum';

export function beforeSend(event: RumEvent) {
  // DB-3470: Discard 'ResizeObserver loop' errors (creates ~100k+ non-fatal error events per hour)
  if (
    event.type === 'error' &&
    event.error.message.includes(
      'ResizeObserver loop completed with undelivered notifications'
    )
  ) {
    return false;
  }
  return true;
}
