import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoCompleteMenu } from '@studio/components/auto-complete-menu';
import {
  FavoriteCreatorProfileSkeleton,
  FavoriteCreatorSearchRow,
} from '@studio/features/settings/components';
import { useFetchChannelStatsList } from '@studio/features/settings/hooks';
import { useMeasure } from '@studio/hooks';
import { useParamStore } from '@studio/stores';
import { vars } from '@lib/theme';
import {
  Button,
  Icons,
  Text,
  TextInput,
  IconButton,
  Select,
  Flex,
} from '@lib/ui';
import { FILTER_PARAMS, SEARCH_BY_VALUES } from '../../constants';
import * as Styles from './search-input.css';

export function OutliersSearchInput() {
  const { t } = useTranslation();
  const { params, setParams, removeParam } = useParamStore();
  const searchTerm = params[FILTER_PARAMS.SEARCH] || '';
  const searchBy = params[FILTER_PARAMS.SEARCH_BY] || '';
  const [openAutoCompleteMenu, setOpenAutoCompleteMenu] = useState(false);
  const [textInputWidth, setTextInputWidth] = useState(0);
  const textInputRef = useRef<HTMLInputElement>(null);
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);
  const itemsRef = useRef<Array<HTMLDivElement | null>>([]);

  const { ref: textInputRootRef, rect } = useMeasure();

  const {
    data: channelList,
    fetchNextPage,
    isFetching,
  } = useFetchChannelStatsList({
    name: searchTerm,
  });

  const creatorsArray =
    channelList?.pages.flatMap((page) => page?.results || []) || [];

  const searchByStringMap = {
    [SEARCH_BY_VALUES.ALL]: t('All'),
    [SEARCH_BY_VALUES.CHANNELS]: t('Channels'),
    [SEARCH_BY_VALUES.TITLES]: t('Titles'),
  } as const;

  const searchPlaceholderStringMap = {
    [SEARCH_BY_VALUES.CHANNELS]: t('Search for channels...'),
    [SEARCH_BY_VALUES.TITLES]: t('Search for titles...'),
  };

  useEffect(() => {
    setTextInputWidth(rect.width);
  }, [rect]);

  const handleTextInputChange = (value: string) => {
    setParams({ [FILTER_PARAMS.SEARCH]: value });

    if (value.length > 0) {
      setOpenAutoCompleteMenu(true);
    }
  };

  const handleSearchByValueChange = (value: string) => {
    setParams({ [FILTER_PARAMS.SEARCH_BY]: value });
  };

  const handleClear = () => {
    removeParam(FILTER_PARAMS.SEARCH);
  };

  const handleSetSearchTerm = (value: string) => {
    handleTextInputChange(value);
    handleAutoCompleteMenuClose();
  };

  const textInputOnFocus = () => {
    if (searchTerm.length > 0) {
      setFocusedIndex(-1);
      setOpenAutoCompleteMenu(true);
    }
  };

  const handleAutoCompleteMenuClose = () => {
    setOpenAutoCompleteMenu(false);
  };

  const handleCallNextPage = () => {
    fetchNextPage();
  };

  const showAutoComplete =
    searchBy !== SEARCH_BY_VALUES.TITLES && searchTerm.length > 0;

  useEffect(() => {
    if (focusedIndex >= 0 && itemsRef.current[focusedIndex]) {
      itemsRef.current[focusedIndex]?.focus();
    }
  }, [focusedIndex]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setFocusedIndex((prevIndex) => {
        // If we're currently focusing the input (i.e., index is -1), focus the first item (index 0)
        if (prevIndex === -1) {
          return 0;
        }
        return prevIndex < creatorsArray.length - 1 ? prevIndex + 1 : 0;
      });
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (focusedIndex === -1) {
        return null;
      } else if (focusedIndex === 0) {
        setFocusedIndex(-1);
        textInputRef.current?.focus();
      } else {
        setFocusedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : creatorsArray.length - 1
        );
      }
    } else if (event.key === 'Enter' && focusedIndex >= 0) {
      const focusedItem = creatorsArray[focusedIndex];
      if (focusedItem) {
        handleSetSearchTerm(focusedItem?.name ?? '');
      }
    } else {
      textInputRef.current?.focus();
    }
  };

  const searchTypeTriggerLabel =
    searchByStringMap[searchBy as keyof typeof searchByStringMap] ||
    searchByStringMap.all;

  // prettier-ignore
  const outliersSearchPlaceholderString =
    !searchBy || searchBy === SEARCH_BY_VALUES.ALL
      ? t('Search for video titles, channel names...')
      : searchPlaceholderStringMap[searchBy as keyof typeof searchPlaceholderStringMap];

  return (
    <Flex data-tour="outliers-search-input" className={Styles.searchWrapper}>
      <TextInput.Root
        ref={textInputRootRef}
        variant="subtle"
        size="lg"
        border="solid"
        fill="none"
      >
        <TextInput.Input
          className={Styles.inputWrapper}
          ref={textInputRef}
          placeholder={outliersSearchPlaceholderString}
          value={searchTerm}
          onFocus={textInputOnFocus}
          onChange={handleTextInputChange}
          onKeyDown={handleKeyDown}
        >
          <TextInput.Adornment style={{ marginLeft: 0 }}>
            <Select.Root
              onValueChange={handleSearchByValueChange}
              onOpenChange={(open) => {
                if (open) {
                  handleAutoCompleteMenuClose();
                }
              }}
            >
              <Select.Trigger
                defaultValue={params.searchBy || searchByStringMap.all}
                label={t('Select search type')}
                asChild
              >
                <Button
                  variant="subtle"
                  size="lg"
                  fill="none"
                  className={Styles.selectTrigger}
                  adornmentEnd={<Icons.ChevronDownIcon aria-hidden />}
                >
                  {searchTypeTriggerLabel}
                </Button>
              </Select.Trigger>
              <Select.Portal>
                <Select.Content align="start">
                  <Select.Item value={SEARCH_BY_VALUES.ALL}>
                    {searchByStringMap.all}
                  </Select.Item>
                  <Select.Item value={SEARCH_BY_VALUES.CHANNELS}>
                    {searchByStringMap.channels}
                  </Select.Item>
                  <Select.Item value={SEARCH_BY_VALUES.TITLES}>
                    {searchByStringMap.titles}
                  </Select.Item>
                </Select.Content>
              </Select.Portal>
            </Select.Root>
          </TextInput.Adornment>
          {searchTerm ? (
            <TextInput.Adornment align="end">
              <IconButton
                fill="none"
                icon={<Icons.CloseIcon aria-hidden />}
                iconSize="16"
                label={t('Clear Search')}
                size="xs"
                onClick={handleClear}
              />
            </TextInput.Adornment>
          ) : (
            <TextInput.Adornment align="end">
              <IconButton
                fill="none"
                icon={<Icons.SearchIcon aria-hidden />}
                iconSize="16"
                label={t('Search')}
                size="xs"
              />
            </TextInput.Adornment>
          )}
        </TextInput.Input>
      </TextInput.Root>
      {showAutoComplete ? (
        <AutoCompleteMenu.Root
          isOpen={openAutoCompleteMenu}
          handleClose={handleAutoCompleteMenuClose}
          handleCallNextPage={handleCallNextPage}
          width={textInputWidth}
        >
          {isFetching && creatorsArray.length === 0
            ? Array.from({ length: 6 }).map((_, i) => (
                <Flex key={i} className={Styles.skeletonWrapper}>
                  <FavoriteCreatorProfileSkeleton key={i} />
                </Flex>
              ))
            : null}
          {!isFetching && creatorsArray.length === 0 ? (
            <Flex
              flexDirection="column"
              gap={vars.scales.s10}
              justifyContent="center"
              alignItems="center"
              width={vars.sizes.full}
              height={vars.sizes.full}
            >
              <Icons.SearchIcon aria-hidden />
              <Text>{t('No results found')}</Text>
            </Flex>
          ) : null}
          {!isFetching &&
            creatorsArray.length > 0 &&
            creatorsArray.map((creator, index) => {
              return (
                <AutoCompleteMenu.Item
                  key={creator.ucid}
                  ref={(el) => (itemsRef.current[index] = el)}
                  onKeyDown={handleKeyDown}
                >
                  <FavoriteCreatorSearchRow
                    rowData={creator}
                    onClick={() =>
                      creator?.name && handleSetSearchTerm(creator.name)
                    }
                  />
                </AutoCompleteMenu.Item>
              );
            })}
        </AutoCompleteMenu.Root>
      ) : null}
    </Flex>
  );
}
