import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIdeationStore } from '@studio/features/projects/stores';
import { TourProvider } from '../tour-provider';
import { useBrainstormFTUESteps } from './steps';

export const BrainstormTourProvider: FC<PropsWithChildren> = ({ children }) => {
  const brainstormSteps = useBrainstormFTUESteps();
  const [steps, setSteps] = useState(brainstormSteps);

  const { ideaPanes } = useIdeationStore();

  const lastStep = useRef(0);
  const [step, setStepState] = useState(0);

  const getCurrentIdeationPaneSelector = useCallback(() => {
    return `.slide-${ideaPanes.length + 1}`;
  }, [ideaPanes]);

  useEffect(() => {
    // call only on change, not on init.
    if (step !== lastStep.current) {
      onStepChange(step);
      lastStep.current = step;
    }
  }, [step]);

  /**
   * execute the onBefore callback for the current step
   */
  const setStep = useCallback(
    (destinationStep: number) => {
      if (steps[destinationStep]?.onBefore) {
        steps[destinationStep].onBefore(getCurrentIdeationPaneSelector());
      }
      setTimeout(() => {
        setStepState(destinationStep);
      }, steps[destinationStep].delay ?? 0);
    },
    [steps]
  );

  /**
   * execute the onAfterLoad callback for the current step
   */
  const onStepChange = (step: number) => {
    if (steps[step]?.onAfterLoad) {
      steps[step]?.onAfterLoad(
        steps,
        setSteps,
        getCurrentIdeationPaneSelector()
      );
    }
  };

  return (
    <TourProvider
      tourId="brainstorm"
      steps={steps}
      currentStep={step}
      setCurrentStep={setStep as Dispatch<SetStateAction<number>>}
    >
      {children}
    </TourProvider>
  );
};
