import { ComponentProps } from 'react';
import { components } from '@reactour/tour';
import { IconButton, Icons } from '@lib/ui';
import * as Styles from './styles.css';

type CloseProps = ComponentProps<typeof components.Close>;

export function Close({ onClick }: CloseProps) {
  return (
    <IconButton
      onClick={onClick}
      icon={<Icons.CloseIcon aria-hidden />}
      iconSize="18"
      radii="pill"
      size="xs"
      className={Styles.CloseButtonWrapper}
    />
  );
}
