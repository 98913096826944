import {
  FormEvent,
  ForwardedRef,
  forwardRef,
  ReactNode,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import clsx from 'clsx';
import {
  Button,
  Dialog,
  Heading,
  IconButton,
  Icons,
  Spinner,
  VisuallyHidden,
} from '@lib/ui';
import { ButtonVariants } from '../button/button.css';
import {
  close,
  dialogVariants,
  DialogVariants,
  footer,
  overlay,
  title,
} from './dialog.css';

/**
 * Dialog
 */

/**
 * Dialog Overlay
 */
type OverlayProps = DialogPrimitive.DialogOverlayProps & {
  children?: ReactNode;
};

const Overlay = forwardRef<HTMLDivElement, OverlayProps>((props, ref) => {
  return (
    <DialogPrimitive.Overlay
      {...props}
      ref={ref}
      className={clsx(overlay, props.className)}
    />
  );
});
Overlay.displayName = 'DialogOverlay';

/**
 * Dialog Trigger
 */
export type TriggerProps = DialogPrimitive.DialogTriggerProps & {
  children?: ReactNode;
};

const Trigger = ({ children, ...props }: TriggerProps) => {
  return (
    <DialogPrimitive.Trigger asChild {...props}>
      {children}
    </DialogPrimitive.Trigger>
  );
};
Trigger.displayName = 'DialogTrigger';

/**
 * Dialog Content
 */
export type DialogContentProps = DialogPrimitive.DialogContentProps &
  DialogVariants & {
    className?: string;
    description?: string;
  };

const Content = forwardRef(
  (props: DialogContentProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { className, description, variant = 'dark', ...restProps } = props;
    const { t } = useTranslation();
    return (
      <DialogPrimitive.Content
        {...restProps}
        ref={ref}
        className={clsx(dialogVariants({ variant }), className)}
      >
        <VisuallyHidden>
          <DialogPrimitive.Description>
            {description || t('Dialog content')}
          </DialogPrimitive.Description>
        </VisuallyHidden>
        {props.children}
      </DialogPrimitive.Content>
    );
  }
);
Content.displayName = 'DialogContent';

/**
 * Dialog Close
 */
export type DialogCloseProps = DialogPrimitive.DialogCloseProps & {
  className?: string;
  onClose?: () => void;
};

const Close = forwardRef(
  (props: DialogCloseProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { className, onClose } = props;
    const { t } = useTranslation();
    return (
      <DialogPrimitive.Close asChild ref={ref}>
        <IconButton
          className={clsx(close, className)}
          icon={<Icons.CloseIcon aria-hidden />}
          iconSize="18"
          label={t('Close')}
          onClick={onClose}
          size="xs"
        />
      </DialogPrimitive.Close>
    );
  }
);
Close.displayName = 'DialogClose';

/**
 * Dialog Close Trigger
 */

const CloseTrigger = forwardRef(
  (props: DialogCloseProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { children } = props;
    return (
      <DialogPrimitive.Close asChild ref={ref} {...props}>
        {children}
      </DialogPrimitive.Close>
    );
  }
);
CloseTrigger.displayName = 'DialogCloseTrigger';

/**
 * Dialog Title
 */
export type DialogTitleProps = DialogPrimitive.DialogTitleProps & {
  children?: ReactNode;
  className?: string;
};

const Title = (props: DialogTitleProps) => {
  const { children, className } = props;
  const { t } = useTranslation();

  if (!children) {
    return (
      <VisuallyHidden>
        <Dialog.Title asChild>{t('Dialog')}</Dialog.Title>
      </VisuallyHidden>
    );
  }

  return (
    <DialogPrimitive.Title asChild {...props}>
      <Heading as="h3" className={clsx(title, className)}>
        {children}
      </Heading>
    </DialogPrimitive.Title>
  );
};
Title.displayName = 'DialogTitle';

/**
 * Dialog Footer
 */
export type DialogFooterProps = {
  children?: ReactNode;
  className?: string;
};

const Footer = (props: DialogFooterProps) => {
  const { children, className } = props;
  return <div className={clsx(footer, className)}>{children}</div>;
};
Footer.displayName = 'DialogFooter';

/**
 * Dialog CTA
 */
export type DialogCTAProps = {
  type?: 'button' | 'submit' | 'reset';
  label: string;
  onClick?: () => void;
  onSubmit?: (event: FormEvent<HTMLButtonElement>) => void;
  autoFocus?: boolean;
  showLoading?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
} & ButtonVariants;

const CTA = forwardRef(
  (props: DialogCTAProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      autoFocus,
      disabled = false,
      showLoading = true,
      isLoading: isCallbackLoading = false,
      type = 'button',
      label,
      onClick,
      onSubmit,
      fill = 'solid',
      variant = 'subtle',
      size = 'sm',
    } = props;

    const [isLoading, setIsLoading] = useState(false);

    const handleOnClick = async () => {
      setIsLoading(true);
      if (onClick) onClick();
      setIsLoading(false);
    };

    const handleOnSubmit = async (event: FormEvent<HTMLButtonElement>) => {
      setIsLoading(true);
      if (onSubmit) onSubmit(event);
      setIsLoading(false);
    };

    return (
      <Button
        ref={ref}
        size={size}
        fill={fill}
        type={type}
        variant={variant}
        onClick={handleOnClick}
        onSubmit={handleOnSubmit}
        autoFocus={autoFocus}
        disabled={disabled || isCallbackLoading || (showLoading && isLoading)}
      >
        {isCallbackLoading || isLoading ? <Spinner size="sm" /> : label}
      </Button>
    );
  }
);
CTA.displayName = 'DialogCTA';

export type DialogProps = DialogPrimitive.DialogProps;

const Root = DialogPrimitive.Root;
const Portal = DialogPrimitive.Portal;

export {
  Content,
  Close,
  CloseTrigger,
  CTA,
  Footer,
  Overlay,
  Portal,
  Root,
  Title,
  Trigger,
};
