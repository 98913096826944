import { useExperiment } from '@statsig/react-bindings';
import { STATSIG_AB_TESTS } from '@lib/utils';
import { PDP_NAV_LOCATIONS } from '../constants';

export const useBrainstormModeNavPlacementAbTest = () => {
  const experiment = useExperiment(
    STATSIG_AB_TESTS.BRAINSTORM_MODE_NAV_PLACEMENT.id
  );

  const navPlacement = experiment.get(
    STATSIG_AB_TESTS.BRAINSTORM_MODE_NAV_PLACEMENT.param,
    PDP_NAV_LOCATIONS.FOOTER
  );

  return {
    navPlacement,
    LOCATIONS: PDP_NAV_LOCATIONS,
  };
};
