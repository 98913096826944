import {
  ChangeEvent,
  forwardRef,
  Ref,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGateValue } from '@statsig/react-bindings';
import { v4 as uuidv4 } from 'uuid';
import { useStore } from 'zustand';
import { promptOptions } from '@studio/features/brainstorm';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import {
  DELETE_THUMBNAIL_MODAL,
  PANEL_CONTROL_INPUTS,
} from '@studio/features/projects/constants';
import {
  useCreateProjectMutation,
  useUploadThumbnailMutation,
} from '@studio/features/projects/hooks';
import { useIdeationStore } from '@studio/features/projects/stores';
import { ProjectQueryParams } from '@studio/features/projects/types';
import { useBrainstorm } from '@studio/hooks';
import {
  ProjectStateType,
  ProjectStoreContext,
  SetRoomProvider,
} from '@studio/providers';
import { useModalStore, useParamStore } from '@studio/stores';
import { useOrganizationStore } from '@studio/stores/organization.store';
import { VideoProjectMetaInput } from '@studio/types';
import { downloadImage } from '@studio/utils';
import {
  EXIT_IDEATION,
  IDEATE_OPTION,
  IdeateOptionType,
  PACKAGE,
  PROJECT_ELEMENT,
} from '@lib/types';
import {
  Button,
  ConfirmDialog,
  Icons,
  IdeationElement,
  MenuAction,
  TextArea,
  Thumbnail,
  Toast,
} from '@lib/ui';
import { STATSIG_FEATURE_FLAGS } from '@lib/utils';
import * as Styles from './project-elements.css';
import { useProjectPackageAnalytics } from './use-project-package-analytics';

/**
 * Allowed image file types
 */
const imageMimeType = /image\/(jpg|jpeg|gif|png|webp)/i;

/**
 * Max image file size
 */
const imageMaxSize = 10 * 1024 * 1024; // 10MB

export const ProjectThumbnail = forwardRef(
  (
    { onBrainstorm, ...props }: IdeationElement.IdeationElementProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const showExplode = useGateValue(
      STATSIG_FEATURE_FLAGS.STUDIO_BRAINSTORM_THUMBNAIL_EXPLODE
    );
    const { hasCreatorDescriptions, hasCastMembersDisabled } =
      useOrganizationStore();

    const { projectStore } = useContext(ProjectStoreContext);
    const {
      id,
      meta,
      thumbnail,
      getPrimaryOption,
      deleteElement,
      updateThumbnailPrompt,
      updateElement,
      removeElementFromPrimary,
    } = useStore(projectStore, (state: ProjectStateType) => ({
      id: state.id,
      thumbnail: state.thumbnail,
      meta: state.meta,
      getPrimaryOption: state.getPrimaryOption,
      updateThumbnailPrompt: state.updateThumbnailPrompt,
      updateElement: state.updateElement,
      deleteElement: state.deleteElement,
      removeElementFromPrimary: state.removeElementFromPrimary,
    }));

    const { setCreated, created } = useContext(SetRoomProvider);

    const type = PROJECT_ELEMENT.THUMBNAIL;

    const {
      onCreateElement,
      onDeleteElement,
      onUploadClick,
      onUploadSuccess,
      onThumbnailDownload,
    } = useProjectPackageAnalytics(projectStore.getState().id);

    const { closeIdeation, startIdeation, ideaPanes } = useIdeationStore();
    const { modals, closeModal, openModal } = useModalStore();

    const { setParams } = useParamStore<ProjectQueryParams>();

    const handleOpenChange = (isOpen: boolean) => {
      isOpen
        ? openModal(DELETE_THUMBNAIL_MODAL)
        : closeModal(DELETE_THUMBNAIL_MODAL);
    };

    const primary = getPrimaryOption(type);

    const channelId = useActiveChannelUcid();
    const { activeOrganizationId: orgId, isSpotterOrgActive } =
      useOrganizationStore();

    const imageFileInputRef = useRef<HTMLInputElement>(null);

    const isCastMemberDisabled = hasCastMembersDisabled(channelId);
    const hasCastMembers = hasCreatorDescriptions(channelId);

    const hasCreatorImage =
      hasCastMembers || isCastMemberDisabled || isSpotterOrgActive();

    const hasThumbnail = primary?.content && primary?.content.trim().length > 0;

    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [promptValue, setPromptValue] = useState<string>(
      thumbnail?.prompt || ''
    );

    const { startBrainstorm } = useBrainstorm(type);

    const onOpenSpider = () => {
      setParams({
        [ProjectQueryParams.PANEL_CONTROL]: PANEL_CONTROL_INPUTS,
        [ProjectQueryParams.ELEMENT]: type,
      });
    };

    const handleInitiateBrainstorm = () => {
      onOpenSpider();
      if (!hasThumbnail) {
        if (onBrainstorm) {
          onBrainstorm({
            properties: {
              thumbnailDescription: projectStore.getState().getPrimary(type),
            },
          });
        }
        startIdeation();
        startBrainstorm({ input: { name: 'emptyState' } });
      }
    };

    const handleExitBrainstorm = () => {
      if (ideaPanes?.length > 0) {
        openModal(EXIT_IDEATION);
      } else {
        closeIdeation();
      }
    };

    const openImageFileUploader = () => {
      onUploadClick();
      imageFileInputRef.current?.click();
    };

    const initiateThumbnailUpload = () =>
      !isUploading ? openImageFileUploader() : null;

    const createProject = useCreateProjectMutation();
    const postThumbnail = useUploadThumbnailMutation();
    const { toast } = Toast.useToast();

    const onInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        if (file && !file?.type?.match(imageMimeType)) {
          toast({
            icon: <Icons.ExclamationCircleIcon />,
            message: t(
              'Please upload an image in jpg, png, gif, or webp format'
            ),
          });

          return false;
        }
        if (file && file?.size > imageMaxSize) {
          toast({
            icon: <Icons.ExclamationCircleIcon />,
            message: t('Please upload an image no larger than 10MB'),
          });
          return false;
        }

        /**
         * File reader for previewing the image
         */
        const reader = new FileReader();
        reader.onload = (event) => {
          handleThumbnailUpload(event.target?.result as string, file.type);
        };
        reader.readAsDataURL(file);
      }
    };

    const resetThumbnail = () => {
      setIsUploading(false);
    };

    const syncUploadToStore = (image = '', id = '') => {
      updateElement('thumbnail', image, id);
    };

    const handleThumbnailUpload = useCallback(
      async (image: string, imageMimeType: string) => {
        const imageId = uuidv4();
        setIsUploading(true);
        let projectId = id;
        if (!created || !created.current) {
          setCreated(true);
          try {
            const project = await createProject.mutateAsync({
              channelId,
              orgId,
              meta: meta as VideoProjectMetaInput,
            });
            projectId = project.videoProjectId;
          } catch (err) {
            console.error(err);
            setCreated(false);
            toast({
              icon: <Icons.ExclamationCircleIcon aria-hidden />,
              message: t(
                'Failed to create a new project. Please try again later'
              ),
            });
            return;
          }
        }
        postThumbnail.mutate(
          {
            image: image,
            imageFileName: `thumbnail-${imageId}`,
            imageMimeType: imageMimeType,
            projectId,
            channelId,
            orgId,
          },
          {
            onError: (error) => {
              if (error.message.includes('S3')) {
                console.error(error);

                toast({
                  icon: <Icons.ExclamationCircleIcon aria-hidden />,
                  message: t(
                    'Failed to upload Thumbnail. Please try again later.'
                  ),
                });
              } else {
                toast({
                  icon: <Icons.ExclamationCircleIcon aria-hidden />,
                  message: t('Thumbnail not added'),
                });
              }
              resetThumbnail();
            },
            onSuccess: (data) => {
              const { cloudfrontURL } = data;
              syncUploadToStore(cloudfrontURL, imageId);
              setIsUploading(false);
              onUploadSuccess();

              toast({
                icon: <Icons.CheckmarkFilledIcon aria-hidden />,
                message: t('Thumbnail added'),
              });
            },
          }
        );
      },
      [meta]
    );

    const handleDelete = () => {
      if (thumbnail.primary) {
        deleteElement('thumbnail', thumbnail.primary);
      }
      closeModal(DELETE_THUMBNAIL_MODAL);
    };

    const initiateDelete = () => {
      openModal(DELETE_THUMBNAIL_MODAL);
      onDeleteElement(type);
    };

    const handleCreate = () => {
      removeElementFromPrimary(type);
      setPromptValue('');
      updateThumbnailPrompt('');
      onCreateElement(type);
    };

    const handlePromptChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setPromptValue(event.target.value);
      updateThumbnailPrompt(event.target.value);
    };

    const handleBrainstorm = (
      variant: IdeateOptionType,
      value?: Record<string, string>
    ) => {
      const properties = {
        thumbnailDescription: projectStore.getState().getPrimary(type),
        ...value,
      };
      if (onBrainstorm) {
        onBrainstorm({ variant, properties });
      }
      startBrainstorm({
        input: {
          name: variant,
          value: properties,
        },
      });
    };

    let includedPrompts = [
      {
        value: IDEATE_OPTION.VARIATIONS,
        handler: () => handleBrainstorm(IDEATE_OPTION.VARIATIONS),
      },
      {
        value: IDEATE_OPTION.EXPLODE,
        handler: () => handleBrainstorm(IDEATE_OPTION.EXPLODE),
      },
      {
        value: IDEATE_OPTION.COMPOSITIONS,
        handler: () => handleBrainstorm(IDEATE_OPTION.COMPOSITIONS),
      },
      {
        value: IDEATE_OPTION.EXPRESSIONS,
        handler: (value?: Record<string, string>) =>
          handleBrainstorm(IDEATE_OPTION.EXPRESSIONS, value),
      },
      {
        value: IDEATE_OPTION.THIS_BUT,
        handler: (value?: Record<string, string>) =>
          handleBrainstorm(IDEATE_OPTION.THIS_BUT, value),
      },
    ];

    if (!showExplode) {
      includedPrompts = includedPrompts.filter(
        (prompt) => prompt.value !== IDEATE_OPTION.EXPLODE
      );
    }

    const ideationOptions = promptOptions(includedPrompts, type);

    const ACTIONS = MenuAction.MENU_ACTION;

    const actions = [
      {
        type: ACTIONS.CREATE_NEW,
        label: t('Add new'),
        icon: <Icons.AddToProjectIcon aria-hidden />,
        handler: handleCreate,
      },
    ];

    if (hasThumbnail) {
      actions.push(
        {
          type: ACTIONS.DOWNLOAD,
          label: t('Download'),
          icon: <Icons.DownloadIcon aria-hidden />,
          handler: () => {
            downloadImage(primary?.content, primary?.id);
            onThumbnailDownload();
          },
        },
        {
          type: ACTIONS.DELETE,
          label: t('Delete'),
          icon: <Icons.DeleteIcon aria-hidden />,
          handler: initiateDelete,
        }
      );
    }

    const gotToCastMembers = () => {
      navigate(`/app/${orgId}/${channelId}/settings/channel`);
    };

    if (!hasCreatorImage) {
      return (
        <IdeationElement.Root
          className="element-thumbnail"
          id={type}
          ref={ref}
          {...props}
        >
          <Thumbnail.Root className={Styles.thumbnailRoot}>
            <div className={Styles.uploadPrompt}>
              <span className={Styles.cta}>
                {t('Upload a profile image to generate thumbnails.')}
              </span>
              <Button variant="primary" fill="ghost" onClick={gotToCastMembers}>
                {t('Update account')}
              </Button>
            </div>
          </Thumbnail.Root>
        </IdeationElement.Root>
      );
    }

    return (
      <IdeationElement.Root
        className="element-thumbnail"
        id={type}
        ref={ref}
        {...props}
      >
        <Thumbnail.Root
          className={Styles.thumbnailRoot}
          isLoading={isUploading}
        >
          {hasThumbnail ? (
            <Thumbnail.ImageViewer
              className={Styles.expandButton}
              icon={<Icons.ExpandIcon aria-label={t('Expand thumbnail')} />}
              src={primary?.content}
            />
          ) : null}
          {hasThumbnail || promptValue ? (
            <IdeationElement.Actions
              className={Styles.thumbnailActions}
              type={type}
              actions={actions}
              align="end"
              alignOffset={-3}
              side="bottom"
            />
          ) : null}
          {!hasThumbnail ? (
            <div className={Styles.fauxContainer}>
              <TextArea
                variant="dark"
                radii="sm"
                size="md"
                resize="none"
                value={promptValue}
                onChange={handlePromptChange}
                placeholder={t('Describe your thumbnail...')}
                className={Styles.fauxContainerInput}
              />
              <Thumbnail.ImageUploadCardLabeled
                onClick={initiateThumbnailUpload}
              />
              <Thumbnail.ImageUploader
                ref={imageFileInputRef}
                onChange={(event) => onInputChange(event)}
              />
            </div>
          ) : (
            <Thumbnail.Image src={primary?.content} />
          )}
        </Thumbnail.Root>
        <IdeationElement.OptionsContainer className={Styles.thumbnailOptions}>
          <IdeationElement.Options
            id={type}
            group={PACKAGE}
            noOptions={!hasThumbnail}
            onBrainstorm={handleInitiateBrainstorm}
            onClose={handleExitBrainstorm}
            onOpen={onOpenSpider}
            options={ideationOptions}
          />
        </IdeationElement.OptionsContainer>

        <ConfirmDialog
          cancelLabel={t('Cancel')}
          confirmLabel={t('Delete')}
          open={modals[DELETE_THUMBNAIL_MODAL]}
          onCancel={() => closeModal(DELETE_THUMBNAIL_MODAL)}
          onConfirm={handleDelete}
          onOpenChange={handleOpenChange}
          title={t('Delete thumbnail')}
        >
          {t(
            'Are you sure you want to delete this thumbnail? This action cannot be undone.'
          )}
        </ConfirmDialog>
      </IdeationElement.Root>
    );
  }
);
