import { useCallback, useRef, type PropsWithChildren } from 'react';
import {
  TourProvider as BaseTourProvider,
  type ProviderProps,
} from '@reactour/tour';
import { px, vars } from '@lib/theme';
import {
  type AugmentedStepType,
  Badge,
  Close,
  Navigation,
  NavigationProps,
} from './components';
import { type Tours } from './tours';
import {
  getPopoverPosition,
  PopoverState,
  isStepTakeover,
  isStepVideo,
  setTourAsSeen,
  isStepImage,
} from './utils';
import { trackTourEvent } from './events';

type Props = ProviderProps & {
  tourId: keyof Tours;
  steps: AugmentedStepType[];
};

export const TourProvider = ({
  children,
  tourId,
  steps,
  currentStep,
  afterOpen,
  ...props
}: PropsWithChildren<Props>) => {
  const opened = useRef(false);

  const onClickMask = useCallback(() => {
    // do nothing instead of closing the tour
  }, []);

  const onClickHighlighted = useCallback(
    (
      _event: MouseEvent,
      clickProps: {
        setCurrentStep: (step: number) => void;
        currentStep: number;
      }
    ) => {
      if (isStepTakeover(steps[clickProps.currentStep])) {
        return;
      }
      clickProps.setCurrentStep(clickProps.currentStep + 1);
    },
    [steps]
  );

  const onAfterOpen: (target: Element | null) => void = (target) => {
    // found that the library is calling this twice
    if (opened.current) {
      return;
    }
    trackTourEvent('onboarding_tour_shown', tourId);
    opened.current = true;
    afterOpen?.(target);
  };

  // styling tweaks for video steps
  const isFullBleedStep =
    isStepVideo(steps[currentStep ?? 0]) ||
    isStepImage(steps[currentStep ?? 0]);
  const width = isFullBleedStep ? px(480) : px(390);
  const topPadding = isFullBleedStep ? px(300) : px(72);

  // TODO: might be able to avoid passing the tour id if we set it via meta
  const NavigationWithId = useCallback(
    (props: NavigationProps) => <Navigation {...props} tourId={tourId} />,
    [tourId]
  );

  return (
    <BaseTourProvider
      steps={steps}
      currentStep={currentStep}
      position="bottom"
      components={{
        Badge: isFullBleedStep ? () => null : Badge,
        Close,
        Navigation: NavigationWithId,
      }}
      styles={{
        popover: (base, state) => ({
          ...base,
          width,
          maxWidth: width,
          backgroundColor: vars.colors.gray750,
          borderRadius: vars.radii.r16,
          padding: `${topPadding} ${vars.scales.s24} ${vars.scales.s24}`,
          boxShadow: `0 ${px(4)} ${px(16)} 0 ${vars.tints.black300}`,
          zIndex: vars.zIndex.z250,
          ...getPopoverPosition(state as PopoverState),
          ...(currentStep === 0
            ? {
                transition: 'none',
              }
            : {}),
        }),
        maskWrapper: (base) => ({
          ...base,
          zIndex: vars.zIndex.z200,
          // takeover steps require additional styling
          ...(isStepTakeover(steps[currentStep ?? 0])
            ? {
                background: 'black',
                width: '100vw',
                height: '100vh',
                pointerEvents: 'auto',
              }
            : {}),
        }),
      }}
      onClickMask={onClickMask}
      onClickClose={(clickProps) => {
        const { currentStep, setIsOpen } = clickProps;
        setIsOpen(false);
        setTourAsSeen(tourId);
        trackTourEvent('onboarding_tour_dismissed', tourId, {
          stepReached: currentStep + 1,
        });
        props.onClickClose?.(clickProps);
      }}
      onClickHighlighted={onClickHighlighted}
      afterOpen={onAfterOpen}
      disableKeyboardNavigation
      disableDotsNavigation
      disableInteraction
      {...props}
    >
      {children}
    </BaseTourProvider>
  );
};
