export const TOUR_IDS = {
  DASHBOARD_BRAINSTORM: 'dashboard-brainstorm',
  DASHBOARD_SURPRISE_ME: 'dashboard-surprise-me',
  PDP_OPTIONS_PANEL: 'pdp-options-panel',
  PDP_TOPIC_INPUT: 'pdp-topic-input',
  PDP_GENERATE_BUTTON: 'pdp-generate-button',
  PDP_IDEATION_CONTAINER: 'pdp-ideation-container',
  PDP_IDEATION_CARD_CONTAINER: 'pdp-ideation-card-container',
  PDP_IDEATION_CARD: 'pdp-ideation-card',
  PDP_KEEP_BUTTON: 'pdp-keep-button',
  PDP_IDEATION_TYPES: 'pdp-ideation-types',
  PDP_IDEATION_SPIDER_MENU: 'pdp-ideation-spider-menu',
  PDP_IDEATION_SPIDER_MENU_CONTAINER: 'pdp-ideation-spider-menu-container',
};
