import { ReactNode } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useInspirationStore } from '@studio/features/brainstorm/stores';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import {
  PANEL_CONTROL_INPUTS,
  PDP_NAV_LOCATIONS,
} from '@studio/features/projects/constants';
import { useBrainstormModeNavPlacementAbTest } from '@studio/features/projects/hooks/use-brainstorm-mode-nav-placement-test';
import { useNewProjectId } from '@studio/features/projects/stores';
import { ProjectQueryParams } from '@studio/features/projects/types';
import { useActiveOrganizationId } from '@studio/stores';
import { PROJECT_ELEMENT, VIDEO_GUIDE } from '@lib/types';
import { BrainstormButton } from '@lib/ui';
import { AugmentedStepType, StepContentBody } from '../components/step-content';
import * as ComponentStyles from '../components/styles.css';
import { getContentComponent, makeSelector } from '../utils';
import { TOUR_IDS } from './constants';

export const useBrainstormFTUESteps = (): AugmentedStepType[] => {
  const { setTopic } = useInspirationStore();
  const navigate = useNavigate();
  const channelUcid = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();
  const { getId } = useNewProjectId();
  const { navPlacement } = useBrainstormModeNavPlacementAbTest();

  const stepsSansContent: Omit<AugmentedStepType, 'content'>[] = [
    {
      selector: '#root',
      title: 'Welcome to Spotter Studio!',
      body: 'Begin your creative journey by brainstorming your first video project and unlocking instant inspiration, tailored to you.',
      type: 'takeover',
      videoSrc: '/assets/ftue-brainstorm.mp4',
      delay: 0,
    },
    {
      selector: makeSelector(TOUR_IDS.DASHBOARD_BRAINSTORM),
      body: "To help steer your Idea Brainstorm, enter a topic that inspired you, select one of the suggested topics or click Surprise Me.\n\nFor now, let's try <strong>Surprise Me</strong>.",
      type: 'step',
      delay: 0,
    },
    {
      onBefore() {
        navigate({
          pathname: `/app/${orgId}/${channelUcid}/projects/new-project`,
          search: createSearchParams({
            [ProjectQueryParams.ELEMENT]: PROJECT_ELEMENT.TITLE,
            [ProjectQueryParams.BRAINSTORM]: 'true',
            [ProjectQueryParams.NEW_PROJECT_ID]: `${getId()}`,
            [ProjectQueryParams.PANEL_CONTROL]: PANEL_CONTROL_INPUTS,
          }).toString(),
        });
      },
      selector: makeSelector(TOUR_IDS.PDP_OPTIONS_PANEL),
      body: "We've already generated initial ideas for you, but you can use the <strong>Inputs Panel</strong> to override the topic or add constraints to guide the AI.\n\nLet's try adding a topic.",
      type: 'step',
      delay: 0,
    },
    {
      onBefore() {
        setTopic('Rocket Ship');
      },
      selector: makeSelector(TOUR_IDS.PDP_TOPIC_INPUT),
      body: 'Here we\'ve added the topic "Rocket Ship".',
      type: 'step',
      delay: 0, // slower than the carousel animation
    },
    {
      selector: makeSelector(TOUR_IDS.PDP_GENERATE_BUTTON),
      body: 'Click <strong>Generate</strong> to see the updated results.',
      type: 'step',
      delay: 0,
    },
    {
      onBefore() {
        const generateButton = document.getElementById(
          TOUR_IDS.PDP_GENERATE_BUTTON
        );
        if (generateButton) {
          generateButton.click();
        }
      },
      onAfterLoad: (steps, setSteps, currentPane) => {
        const updatedSteps = [...steps];

        const selector = `${currentPane} ${makeSelector(
          TOUR_IDS.PDP_IDEATION_CARD_CONTAINER
        )}`;
        updatedSteps[5].selector = selector;
        updatedSteps[5].mutationObservables = [selector];
        updatedSteps[5].resizeObservables = [selector];
        setSteps(updatedSteps);
      },
      selector: makeSelector(TOUR_IDS.PDP_IDEATION_CARD_CONTAINER),
      mutationObservables: [makeSelector(TOUR_IDS.PDP_IDEATION_CONTAINER)],
      resizeObservables: [makeSelector(TOUR_IDS.PDP_IDEATION_CONTAINER)],
      position: 'left',
      body: "Here are your new video title ideas! They are clearly about rocket ships, but they're all ideas unique you your brand and channel. You can click ::ideation-keep-label:: on any you like to start your new video project.\n\nLet's keep the first one for now.",
      type: 'step',
      delay: 800,
    },
    {
      onBefore(currentPane) {
        const selector = `${currentPane} ${makeSelector(
          TOUR_IDS.PDP_KEEP_BUTTON
        )}`;

        const keepButton = document.querySelector(
          selector
        ) as HTMLButtonElement;
        if (keepButton) {
          keepButton.click();
        }
      },
      onAfterLoad(steps, setSteps, currentPane) {
        // if a/b test value is "more" the ideation types control will
        // be in the latest ideation column, so update the selector/observables
        if (navPlacement !== PDP_NAV_LOCATIONS.MORE) {
          return;
        }

        const updatedSteps = [...steps];
        const selector = `${currentPane} ${makeSelector(
          TOUR_IDS.PDP_IDEATION_TYPES
        )}`;

        updatedSteps[6].selector = selector;
        updatedSteps[6].mutationObservables = [selector];
        updatedSteps[6].resizeObservables = [selector];

        setSteps(updatedSteps);
      },
      selector: makeSelector(TOUR_IDS.PDP_IDEATION_TYPES),
      body: "Now that we've chosen a title, we can generate a matching thumbnail idea or hook! Let's click Thumbnail to switch modes.",
      type: 'step',
      delay: 0,
    },
    {
      onBefore(currentPane) {
        let typesSelector = makeSelector(TOUR_IDS.PDP_IDEATION_TYPES);

        if (navPlacement === PDP_NAV_LOCATIONS.MORE) {
          typesSelector = `${currentPane} ${typesSelector}`;
        }

        const thumbnailButtonSelector = `${typesSelector} button:first-child`;
        const conceptButtonSelector = `${typesSelector} button:last-child`;
        const thumbnailButton = document.querySelector(
          thumbnailButtonSelector
        ) as HTMLButtonElement;
        const conceptButton = document.querySelector(
          conceptButtonSelector
        ) as HTMLButtonElement;

        if (
          thumbnailButton &&
          thumbnailButton.innerText.includes('Thumbnail')
        ) {
          thumbnailButton.click();
        } else if (conceptButton) {
          conceptButton.click();
        }
      },
      onAfterLoad(steps, setSteps, currentPane) {
        const updatedSteps = [...steps];

        const selector = `${currentPane} ${makeSelector(
          TOUR_IDS.PDP_IDEATION_CARD
        )}`;

        updatedSteps[7].selector = selector;
        updatedSteps[7].mutationObservables = [selector];
        updatedSteps[7].resizeObservables = [selector];

        setSteps(updatedSteps);
      },
      selector: makeSelector(TOUR_IDS.PDP_IDEATION_CARD),
      mutationObservables: [makeSelector(TOUR_IDS.PDP_IDEATION_CARD)],
      resizeObservables: [makeSelector(TOUR_IDS.PDP_IDEATION_CARD)],
      // extra padding to reveal more of the yellow brainstorm btn
      padding: 18,
      position: 'left',
      body: "We think these thumbnails might pair well with your title. But these are just starting points. You can refine and explore until you're truly inspired by clicking ::brainstorm-icon:: on any generated idea. Let's give it a try on this one.",
      type: 'step',
      delay: 800,
    },
    {
      onBefore(currentPane) {
        const menuContainerSelector = `${currentPane} ${makeSelector(
          TOUR_IDS.PDP_IDEATION_SPIDER_MENU_CONTAINER
        )}`;

        const btn = document.querySelector(
          `${menuContainerSelector} button`
        ) as HTMLButtonElement;
        btn?.click();
      },
      onAfterLoad(steps, setSteps, currentPane) {
        const updatedSteps = [...steps];
        const menuContainerSelector = `${currentPane} ${makeSelector(
          TOUR_IDS.PDP_IDEATION_SPIDER_MENU_CONTAINER
        )}`;
        const selector = `${menuContainerSelector} ${makeSelector(
          TOUR_IDS.PDP_IDEATION_SPIDER_MENU
        )}`;
        updatedSteps[8].selector = selector;
        updatedSteps[8].mutationObservables = [selector];
        updatedSteps[8].resizeObservables = [selector];
        setSteps(updatedSteps);
      },
      selector: makeSelector(TOUR_IDS.PDP_IDEATION_SPIDER_MENU),
      mutationObservables: [
        makeSelector(TOUR_IDS.PDP_IDEATION_SPIDER_MENU_CONTAINER),
      ],
      body: "On thumbnails, you can generate variations, change an expression or the composition of the shot, or make it your own via 'This, but'.\n\nWhen you're done with the tour, make sure to explore all available options across titles, hooks and thumbnails.",
      type: 'step',
      delay: 0,
    },
    {
      selector: '#root',
      // TODO: is it no longer desired to have the last step link to the Feature Guide video?
      body: "Great job! You're now equipped with the essentials of how to brainstorm new video ideas in Spotter Studio.\n\nAfter the tour head back to your dashboard by clicking 'Home' on your left to start your next brainstorm.",
      type: 'takeover',
      delay: 0,
    },
  ];

  const steps: AugmentedStepType[] = stepsSansContent.map((step) => {
    const tokens = Object.values(replacementTokens);
    const Content = getContentComponent(step);

    if (
      typeof step.body === 'string' &&
      tokens.some((token) => (step.body as string).includes(token))
    ) {
      step.body = replaceTokens(step.body, {
        // TODO: is it no longer desired to have the last step link to the Feature Guide video?
        openModal: () => {},
        // TODO: don't we need to use the a/b test value?
        keepLabel: 'keep',
      });
    }

    return {
      ...step,
      content: () => <Content {...step} />,
      navDotAriaLabel: '',
    };
  });

  return steps;
};

const replacementTokens = {
  icon: '::brainstorm-icon::',
  guide: '::brainstorm-feature-guide::',
  keep: '::ideation-keep-label::',
};

const replaceTokens = (
  text: ReactNode,
  helpers: {
    openModal: (id: string) => void;
    keepLabel: string;
  }
) => {
  if (!text) {
    return '';
  }

  let replaced = <span>{text}</span>;
  let split = text.toString().split(replacementTokens.icon);

  if (split.length > 1) {
    replaced = (
      <>
        <span>{split[0]}</span>
        <BrainstormButton isInteractable={false} />
        <span>{split[1]}</span>
      </>
    );
  }

  split = text.toString().split(replacementTokens.guide);

  if (split.length > 1) {
    replaced = (
      <>
        <span>{split[0]}</span>
        <StepContentBody
          onClick={() => helpers.openModal(VIDEO_GUIDE)}
          className={ComponentStyles.StepLink}
        >
          Brainstorm feature guide
        </StepContentBody>
        <span>{split[1]}</span>
      </>
    );
  }

  // because "Keep" is presently under an A/B test where it may read "Select"
  split = text.toString().split(replacementTokens.keep);

  if (split.length > 1) {
    replaced = (
      <>
        <span>{split[0]}</span>
        <strong>{helpers.keepLabel}</strong>
        <span>{split[1]}</span>
      </>
    );
  }

  return replaced;
};
