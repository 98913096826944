import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function UploadIcon({ className, ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      className={clsx(iconStyles, className)}
      {...props}
    >
      <path
        d="M0.5 7.25V8.375C0.5 8.99632 1.00368 9.5 1.625 9.5H8.375C8.99632 9.5 9.5 8.99632 9.5 8.375V7.25M2.75 2.75L5 0.5M5 0.5L7.25 2.75M5 0.5L5 7.25"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
