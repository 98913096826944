import { ForwardedRef, forwardRef } from 'react';
import * as Form from '@radix-ui/react-form';
import clsx from 'clsx';
import { Label as LabelPrimitive, LabelProps } from '../label';
import * as Styles from './form.css';

/**
 * Root
 */
export type FormRootProps = Form.FormProps;

export const Root = forwardRef(
  (props: FormRootProps, ref: ForwardedRef<HTMLFormElement>) => {
    const { className, ...rest } = props;
    return (
      <Form.Root {...rest} className={clsx(Styles.root, className)} ref={ref} />
    );
  }
);

Root.displayName = 'FormRoot';

/**
 * Field
 */
export type FormFieldProps = Form.FormFieldProps;

export const Field = forwardRef(
  (props: FormFieldProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { className, ...rest } = props;
    return (
      <Form.Field
        {...rest}
        className={clsx(Styles.field, className)}
        ref={ref}
      />
    );
  }
);

Field.displayName = 'FormField';

/**
 * Label
 */
export type FormLabelProps = Form.FormLabelProps & LabelProps;
export const Label = forwardRef(
  (props: FormLabelProps, ref: ForwardedRef<HTMLLabelElement>) => {
    const { className, ...rest } = props;
    return (
      <LabelPrimitive
        {...rest}
        className={clsx(Styles.label, className)}
        ref={ref}
      />
    );
  }
);

Label.displayName = 'FormLabel';

/**
 * Control
 */
export type FormControlProps = Form.FormControlProps;
export const Control = forwardRef(
  (props: FormControlProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { className, ...rest } = props;
    return (
      <Form.Control {...rest} className={className} ref={ref} asChild={true} />
    );
  }
);

Control.displayName = 'FormControl';

/**
 * Message
 */
export type FormMessageProps = Form.FormMessageProps;
export const Message = forwardRef(
  (props: FormMessageProps, ref: ForwardedRef<HTMLSpanElement>) => {
    const { className, ...rest } = props;
    return (
      <Form.Message
        {...rest}
        className={clsx(Styles.message, className)}
        ref={ref}
      />
    );
  }
);

Message.displayName = 'FormMessage';

/**
 * Error
 */
export type FormErrorProps = Form.FormMessageProps;
export const Error = forwardRef(
  (props: FormErrorProps, ref: ForwardedRef<HTMLSpanElement>) => {
    const { className, ...rest } = props;
    return (
      <Form.Message
        {...rest}
        className={clsx(Styles.error, className)}
        ref={ref}
      />
    );
  }
);

Error.displayName = 'FormError';

/**
 * Submit
 */
export type FormSubmitProps = Form.FormSubmitProps;
export const Submit = forwardRef(
  (props: FormSubmitProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { className, ...rest } = props;
    return <Form.Submit {...rest} className={className} ref={ref} />;
  }
);

Submit.displayName = 'FormSubmit';

export const ValidityState = Form.ValidityState;
