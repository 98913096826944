import { PropsWithChildren, useEffect } from 'react';
import { type NavigateFunction } from 'react-router-dom';
import clsx from 'clsx';
import { LogoMark as SpotterLogoMark, Logo as SpotterLogo } from '../logo';
import { SidebarProvider, useSidebar } from './context';
import * as Styles from './sidebar.css';
import type { OverridableStyles } from '../../types';

/**
 * Navigation Root
 */
export type NavigationRootProps = PropsWithChildren & {
  navigate?: NavigateFunction;
};

export function Root(props: NavigationRootProps) {
  return (
    <SidebarProvider navigate={props.navigate}>
      {props.children}
    </SidebarProvider>
  );
}

/**
 * Navigation Container
 */
export type NavigationContainerProps = PropsWithChildren & OverridableStyles;

export function Container(props: NavigationContainerProps) {
  const { collapsed } = useSidebar();
  return (
    <nav
      className={clsx(
        Styles.container,
        collapsed && 'collapsed',
        props.className
      )}
    >
      {props.children}
    </nav>
  );
}

/**
 * Navigation Logo
 */
export type NavigationLogoProps = OverridableStyles;

export function Logo(props: NavigationLogoProps) {
  const { collapsed } = useSidebar();
  return (
    <div className={clsx(Styles.logo, props.className)}>
      {collapsed ? <SpotterLogoMark /> : <SpotterLogo />}
    </div>
  );
}

/**
 * Navigation Items List
 */
export type NavigationItemsListProps = PropsWithChildren & OverridableStyles;

export function ItemsList(props: NavigationItemsListProps) {
  return (
    <ul className={clsx(Styles.itemsList, props.className)}>
      {props.children}
    </ul>
  );
}

/**
 * Navigation Item
 */
export type NavigationItemProps = PropsWithChildren & OverridableStyles;

export function Item(props: NavigationItemProps) {
  return <li className={props.className}>{props.children}</li>;
}

/**
 * Navigation Divider
 */
export function Divider() {
  return <li className={clsx(Styles.divider)} />;
}

/**
 * Navigation Trigger
 */
export type NavigationTriggerProps = PropsWithChildren &
  OverridableStyles & {
    active?: boolean;
    currentPath?: string;
    href?: string;
    slug?: string;
    label?: string;
    onClick?: () => void;
  };

export function Trigger(props: NavigationTriggerProps) {
  const { collapsed, setActiveSlug } = useSidebar();

  useEffect(() => {
    if (props.active && props.slug) {
      setActiveSlug(props.slug);
    }
  }, [props.active, props.slug, setActiveSlug]);

  const handleNavigate = () => {
    const to = props.href || '';
    const { onClick, currentPath } = props;

    if (to === currentPath) {
      return;
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      id={props.slug}
      onClick={handleNavigate}
      aria-label={props.label}
      disabled={props.active}
      className={clsx(
        Styles.trigger,
        props.active && 'active',
        collapsed && 'collapsed',
        props.className
      )}
    >
      {props.children}
    </button>
  );
}

export type NavigationLinkProps = PropsWithChildren &
  OverridableStyles & {
    active?: boolean;
    currentPath?: string;
    href?: string;
    external?: boolean;
    onClick?: () => void;
  };

export function Link(props: NavigationLinkProps) {
  const { collapsed } = useSidebar();

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <a
      href={props.href}
      target={props.external ? '_blank' : undefined}
      rel="noreferrer"
      onClick={handleClick}
      className={clsx(
        Styles.trigger,
        props.active && 'active',
        collapsed && 'collapsed',
        props.className
      )}
    >
      {props.children}
    </a>
  );
}
