import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import { OUTLIERS_FILTERS } from '../../../data';

type AccordionStates = {
  items: string[];
  addItem: (item: string) => void;
  removeItem: (item: string) => void;
  clear: () => void;
};

export const useFilterDropdownStore = create<AccordionStates>()(
  devtools(
    persist(
      (set, get) => ({
        items: [
          // Default items to be opened
          OUTLIERS_FILTERS.OUTLIER_INDEX,
          OUTLIERS_FILTERS.UPLOAD_DATE,
          OUTLIERS_FILTERS.SUBSCRIBER_COUNT,
        ],
        addItem: (item) => {
          const tempSet = new Set(get().items);
          tempSet.add(item);
          set({ items: [...tempSet] });
        },
        removeItem: (item) => {
          const filteredItems = get().items.filter((_item) => _item !== item);
          set({ items: filteredItems });
        },
        clear: () => set({ items: [] }),
      }),
      {
        name: 'outliers_filters_accordion',
      }
    )
  )
);
