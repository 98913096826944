import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react';
import { TourProvider } from '../tour-provider';
import { useProjectsFTUESteps } from './steps';

export const ProjectsTourProvider: FC<PropsWithChildren> = ({ children }) => {
  const [step, setStep] = useState(0);
  const steps = useProjectsFTUESteps();

  const setCurrentStep = (destinationStep: number) => {
    const step = steps[destinationStep];
    step.onBefore?.();
    setTimeout(() => {
      setStep(destinationStep);
    }, step.delay ?? 0);
  };

  return (
    <TourProvider
      tourId="projects"
      steps={steps}
      currentStep={step}
      setCurrentStep={setCurrentStep as Dispatch<SetStateAction<number>>}
    >
      {children}
    </TourProvider>
  );
};
