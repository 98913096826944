export function getLocalStorage() {
  function getItem<T>(key: string): T | null {
    try {
      const storedValue = localStorage.getItem(key);
      return storedValue ? (JSON.parse(storedValue) as T) : null;
    } catch (error) {
      console.error(
        `Error getting item from localStorage with key "${key}":`,
        error
      );
      return null;
    }
  }

  function setItem<T>(key: string, value: T) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(
        `Error setting item to localStorage with key "${key}":`,
        error
      );
    }
  }

  function removeItem(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(
        `Error removing item from localStorage with key "${key}":`,
        error
      );
    }
  }

  return { getItem, setItem, removeItem };
}
