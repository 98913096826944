import { format } from 'date-fns';

/**
 * Formats a given date object into a string with the format `YYYY.DD.MM-HH:MM`.
 *
 * If no date is provided, the current date and time will be used.
 */
export function formatTimestamp(date: Date = new Date()) {
  return format(date, 'MM.dd.yyyy-HH:mm');
}
