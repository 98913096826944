import {
  forwardRef,
  useRef,
  type ChangeEvent,
  type ForwardedRef,
  type InputHTMLAttributes,
  type PropsWithChildren,
} from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Icons, IconButton } from '@lib/ui';
import { HEAP_REPLAY } from '../../constants';
import * as Styles from './text-input-inline.css';

/**
 * --------------------------------------------------------
 * TextInputInline Root
 * --------------------------------------------------------
 */
export type TextInputInlineRootProps = PropsWithChildren & {
  className?: string;

  /**
   * Puts the input itself into an "errored" visual state
   */
  hasError?: boolean;

  /**
   * On click callback
   */
  onClick?: () => void;

  /**
   * On click callback
   */
  onBlur?: () => void;

  /**
   * On focus callback
   */
  onFocus?: () => void;
};

export const Root = forwardRef(
  (props: TextInputInlineRootProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, className, hasError, onClick, onFocus, onBlur } = props;

    return (
      <div
        className={clsx(Styles.root, { [Styles.error]: hasError }, className)}
        onClick={onClick}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

Root.displayName = 'TextInputInlineRoot';

/**
 * --------------------------------------------------------
 * TextInputInline Input
 * --------------------------------------------------------
 */
export type TextInputInlineInputProps = PropsWithChildren &
  Styles.TextInputInlineVariants &
  Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'onBlur' | 'onChange' | 'value' | 'size'
  > & {
    id?: string;
    /**
     * Boolean to display the adornment on mouseover
     */
    includeAdornment?: boolean;

    /**
     * Boolean to always display the adornment
     */
    alwaysShowAdornment?: boolean;

    /**
     * Input value
     */
    value?: string;

    /**
     * Callback to get the input's value as a string on `blur`.
     *
     * Optionally provides the entire event just in case you need it.
     */
    onBlur?: (value: string) => void;

    /**
     * Callback to get the input's value as a string on `change`.
     *
     * Optionally provides the entire event just in case you need it.
     */
    onChange: (value: string) => void;

    /**
     * Affects top and bottom padding
     */
    size?: 'xs' | 'sm' | 'md' | 'lg';
  };

export const Input = (props: TextInputInlineInputProps) => {
  const {
    children,
    className,
    disabled,
    id,
    includeAdornment = true,
    alwaysShowAdornment = false,
    onBlur,
    onChange,
    size = 'xs',
    value = '',
    ...inputProps
  } = props;

  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    if (onChange) {
      onChange(value);
    }
  };

  const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    if (onBlur) {
      onBlur(value);
    }
  };

  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className={clsx(Styles.textInputInlineVariants({ size }), className)}>
      <input
        data-element="text-input"
        ref={inputRef}
        className={clsx(Styles.input, className, {
          [HEAP_REPLAY]: inputProps.type !== 'password',
        })}
        disabled={disabled}
        id={id}
        onBlur={handleBlur}
        onChange={handleChange}
        type={inputProps.type}
        {...inputProps}
        value={value}
        size={4}
      />
      {includeAdornment ? (
        <span
          className={clsx({
            [Styles.adornment]: true,
            alwaysShowAdornment,
          })}
        >
          <IconButton
            aria-hidden
            label={t('Click to edit title')}
            size="xs"
            onClick={handleIconClick}
            icon={<Icons.EditIcon aria-hidden />}
          />
        </span>
      ) : null}
      <span className={Styles.sizer}>{value}</span>
    </div>
  );
};

Input.displayName = 'TextInputInlineInput';
