import { ROLES } from '../constants';
import { ChannelRoleRouteGuard } from './channel-role-route-guard';

export const settingsRoutes = [
  /**
   * Profile Settings
   */
  {
    path: 'settings/profile',
    async lazy() {
      const { ProfileSettings } = await import('./routes/profile');
      return {
        Component: ProfileSettings,
      };
    },
  },

  /**
   * Channel Settings
   */
  {
    // DO NOT CHANGE this route without also changing the logic inside the channel selection component
    path: ':organizationId/:channelUcid/settings/channel',
    async lazy() {
      const { ChannelSettings } = await import('./routes/channel');
      return {
        Component: ChannelSettings,
      };
    },
  },

  /**
   * Admin and Owner only Routes
   */
  {
    element: (
      <ChannelRoleRouteGuard
        allowedRoles={[ROLES.PRIMARY, ROLES.ADMIN]}
        redirectRoute="/app/settings/profile"
      />
    ),
    children: [
      /**
       * Team Settings
       */
      {
        path: ':organizationId/:channelUcid/settings/team/',
        async lazy() {
          const { TeamSettings } = await import('./routes/team');
          return {
            Component: TeamSettings,
          };
        },
      },
    ],
  },

  /**
   * Billing Settings
   */
  {
    element: (
      <ChannelRoleRouteGuard
        allowedRoles={[ROLES.PRIMARY]}
        redirectRoute="/app/settings/profile"
      />
    ),
    children: [
      {
        path: 'settings/billing',
        async lazy() {
          const { BillingSettings } = await import('./routes/billing');
          return {
            Component: BillingSettings,
          };
        },
      },
    ],
  },

  /**
   * Referral page
   */
  {
    element: (
      <ChannelRoleRouteGuard
        allowedRoles={[ROLES.PRIMARY]}
        redirectRoute="/app/settings/profile"
      />
    ),
    children: [
      {
        path: ':organizationId/settings/referral',
        async lazy() {
          const { ReferralSettings } = await import('./routes/referral');
          return {
            Component: ReferralSettings,
          };
        },
      },
    ],
  },
];
