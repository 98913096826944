import { ForwardedRef, forwardRef } from 'react';
import * as ToggleGroupPrimitives from '@radix-ui/react-toggle-group';
import clsx from 'clsx';
import * as Styles from './toggle-filter.css';

/**
 * Toggle Filter Root
 */
export const Root = forwardRef(
  (
    props: ToggleGroupPrimitives.ToggleGroupSingleProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <ToggleGroupPrimitives.Root
        ref={ref}
        {...props}
        className={clsx(Styles.root, props.className)}
      />
    );
  }
);
Root.displayName = 'ToggleFilterRoot';

/**
 * Toggle Filter Item
 */
export const Item = forwardRef(
  (
    props: ToggleGroupPrimitives.ToggleGroupItemProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <ToggleGroupPrimitives.Item
        ref={ref}
        {...props}
        className={clsx(Styles.item, props.className)}
      />
    );
  }
);
Item.displayName = 'ToggleFilterItem';
