import React, {
  forwardRef,
  KeyboardEventHandler,
  MutableRefObject,
  ReactNode,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { autoUpdate, useFloating } from '@floating-ui/react-dom';
import clsx from 'clsx';
import { vars } from '@lib/theme';
import {
  IDEATE_OPTION,
  IdeateOptionType,
  OptionItem,
  PROJECT_ELEMENT,
  PowerKeyword as PowerKeywordType,
  ProjectElementType,
} from '@lib/types';
import {
  BrainstormButton,
  Button,
  Card,
  Icons,
  PowerKeywords,
  Spinner,
  Text,
  Tooltip,
} from '@lib/ui';
import { Switch } from '@lib/utils';
import { ExpressionList } from './expression-list';
import * as Styles from './ideation-element.css';
import { IdeationTextArea } from './ideation-textarea';
import { ThisButEditor } from './this-but-editor';
import { IdeationSpiderThread, useSpiderStore } from './';

/**
 * Ideation Element
 */

export type IdeationElementProps = {
  children?: ReactNode;
  className?: string;
  id?: string;
  ideation?: boolean;
  ideating?: boolean;
  backupMode?: boolean;
  onBrainstorm?: ({
    variant,
    properties,
  }: {
    variant?: IdeateOptionType;
    properties?: Record<string, string | undefined>;
  }) => void;
  type?: ProjectElementType;
};

export const Root = forwardRef(
  (
    {
      className,
      children,
      ideation = true,
      backupMode = false,
      onBrainstorm,
      type,
      ...props
    }: IdeationElementProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(Styles.ideationContainer, className)}
        {...props}
      >
        {children}
      </div>
    );
  }
);

Root.displayName = 'IdeationElementRoot';

type ContentProps = {
  children?: ReactNode;
  className?: string;
  ideation?: boolean;
  backupMode?: boolean;
  onBrainstorm?: () => void;
  type?: ProjectElementType;
};

export const Content = forwardRef(
  (
    {
      className,
      children,
      ideation = true,
      backupMode = false,
      onBrainstorm,
      type,
      ...props
    }: ContentProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(className, Styles.ideationCardContainer)}
        {...props}
      >
        {children}
      </div>
    );
  }
);

Content.displayName = 'IdeationElementContent';

export const IdeationCard = ({
  className,
  children,
  ...props
}: Card.CardProps) => {
  return (
    <Card.Root {...props} className={className}>
      {children}
    </Card.Root>
  );
};

IdeationCard.displayName = 'IdeationElementIdeationCard';

export const IdeationCardHeader = ({
  className,
  children,
  ...props
}: Card.HeaderProps) => {
  return (
    <Card.Header {...props} className={className}>
      {children}
    </Card.Header>
  );
};

IdeationCardHeader.displayName = 'IdeationElementIdeationCardHeader';

type IdeationCardContentProps = Card.CardProps & {
  onTextChange?: (val: string) => void;
  onBlur?: () => void;
  type?: ProjectElementType;
  powerKeywords?: PowerKeywordType[];
  maxLength?: number;
  textContent?: string;
  readOnly?: boolean;
  onPowerKeywordHover?: (keywordData: PowerKeywordType) => void;
  onPowerKeywordClick?: (keywordData: PowerKeywordType) => void;
};

export const IdeationCardContent = forwardRef<
  HTMLDivElement,
  IdeationCardContentProps
>(
  (
    {
      className,
      children,
      onBlur,
      onTextChange,
      powerKeywords,
      textContent,
      readOnly = false,
      onPowerKeywordHover,
      onPowerKeywordClick,
      maxLength,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [isMouseOver, setMouseOver] = useState<boolean>(false);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const isTitle = props.variant === PROJECT_ELEMENT.TITLE;
    const isConcept = props.variant === PROJECT_ELEMENT.CONCEPT;
    const isText = isTitle || isConcept;

    const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (
      event
    ) => {
      // Check if the content consists only of whitespace
      const eventTarget = event.target as HTMLTextAreaElement;
      const isContentEmptyOrWhitespace = eventTarget.value.trim() === '';

      const disallowNewLine =
        props.variant === PROJECT_ELEMENT.TITLE || isContentEmptyOrWhitespace;

      if (event.key === 'Enter') {
        if (disallowNewLine) {
          event.preventDefault();

          // Disallow new line on Shift + Enter for titles
          if (event.shiftKey) {
            return;
          }
        }
        // Blur event toggles edit mode
        // On both titles and concept, toggle edit mode on Enter
        if (!event.shiftKey) {
          eventTarget.blur();
        }
      }
    };

    const handlePointerEnter = () => {
      setMouseOver(true);
    };

    const handlePointerLeave = () => {
      setMouseOver(false);
    };

    const showMaxLengthError = useMemo(() => {
      const stringValue = children as string;
      return !!maxLength && stringValue.length >= maxLength;
    }, [children, maxLength]);

    if (isText) {
      const isTextAreaEmpty = !String(children).trim();

      const hasError = isTextAreaEmpty || showMaxLengthError;

      return (
        <>
          <Card.Content
            {...props}
            onPointerEnter={handlePointerEnter}
            onPointerLeave={handlePointerLeave}
            className={clsx(Styles.textAreaWrapper, className)}
            ref={ref}
          >
            <IdeationTextArea
              showMaxLengthError={showMaxLengthError}
              showAdornment={isMouseOver && !readOnly}
              autoHeight
              border="solid"
              className={Styles.textArea[hasError ? 'error' : 'default']}
              fill="none"
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                if (onTextChange) onTextChange(e.target.value);
              }}
              ref={textareaRef}
              resize="none"
              rows={1}
              value={textContent as string}
              readOnly={readOnly}
              maxLength={maxLength}
              variant="light"
              onBlur={onBlur}
              hasError={hasError}
            />
            {powerKeywords && textContent ? (
              <PowerKeywords
                powerKeywords={powerKeywords}
                className={Styles.powerKeywordsContainer}
                text={textContent}
                onPowerKeywordHover={onPowerKeywordHover}
                onPowerKeywordClick={onPowerKeywordClick}
              />
            ) : null}
          </Card.Content>
          {isTextAreaEmpty ? (
            <Text size="11" className={Styles.textError}>
              {t('Cannot be empty')}
            </Text>
          ) : null}
        </>
      );
    }
    return (
      <Card.Content
        {...props}
        className={clsx(Styles.card, className)}
        ref={ref}
      >
        {children}
      </Card.Content>
    );
  }
);

IdeationCardContent.displayName = 'IdeationElementIdeationCardContent';

type OptionsContainerProps = {
  children?: ReactNode;
  className?: string;
};

export const OptionsContainer = forwardRef(
  (props: OptionsContainerProps, ref: Ref<HTMLDivElement>) => {
    const { children, className } = props;
    return (
      <div
        ref={ref}
        className={clsx(Styles.menuContainer, className)}
        // using string instead of @studio constant to avoid circular dependency
        data-tour="pdp-ideation-spider-menu-container"
      >
        {children}
      </div>
    );
  }
);
OptionsContainer.displayName = 'IdeationElementOptionsContainer';

type OptionButtonProps = {
  option: OptionItem;
  onSelect: (value: string) => void;
  children?: ReactNode;
};

export const OptionButton = (props: OptionButtonProps) => {
  const { onSelect } = props;
  const { label, selected = false, tooltip, value } = props.option;
  const { t } = useTranslation();
  const [container, setContainer] = useState<HTMLDivElement | null>(
    document.body as HTMLDivElement
  );

  const containerRef = useRef(null);

  useEffect(() => {
    setContainer(containerRef.current);
  }, []);

  const handleClick = useCallback(() => {
    onSelect(value);
  }, [onSelect, value]);

  const buttonClasses = clsx(Styles.optionsButton, {
    selected: selected,
    unselected: !selected,
    [Styles.optionsButtonExplode]: value === IDEATE_OPTION.EXPLODE,
    [Styles.optionsButtonMoodShift]: value === IDEATE_OPTION.MOOD_SHIFT,
  });

  if (!tooltip) {
    return (
      <Button
        key={value}
        variant="subtle"
        fill="ghost"
        radii="pill"
        className={buttonClasses}
        onClick={handleClick}
      >
        {label}
        <Icons.KeyboardArrowRightIcon
          className={Styles.optionsIcon}
          aria-hidden
        />
      </Button>
    );
  }

  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={200}>
        <Tooltip.Trigger asChild>
          <div ref={containerRef}>
            <Button
              key={value}
              variant="subtle"
              fill="ghost"
              radii="pill"
              className={buttonClasses}
              onClick={handleClick}
            >
              {t(`${label}`)}
              <Icons.KeyboardArrowRightIcon
                className={Styles.optionsIcon}
                aria-hidden
              />
            </Button>
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal container={container}>
          <Tooltip.Content side="right" sideOffset={2}>
            <Tooltip.Arrow />
            {t(`${tooltip}`)}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

type OptionsProps = {
  brainstormLabel?: string;
  className?: string;
  id: string;
  group: string;
  noOptions?: boolean;
  onBrainstorm?: () => void;
  options?: OptionItem[];
  type?: ProjectElementType;
  onClose?: () => void;
  onOpen?: () => void;
};

export const Options = (props: OptionsProps) => {
  const {
    brainstormLabel,
    className,
    id,
    group,
    noOptions,
    onBrainstorm,
    onClose,
    onOpen,
    options = [],
  } = props;

  const { t } = useTranslation();
  const { spiders, openSpider, closeSpider } = useSpiderStore();
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [showThisBut, setShowThisBut] = useState(false);
  const [thisButMinimized, setThisButMinimized] = useState<boolean>(false);
  const [thisButValue, setThisButValue] = useState<string>('');
  const [showExpressions, setShowExpressions] = useState(false);

  const spiderOpen = spiders[group] === id;

  const elementRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef(null);

  const { refs, floatingStyles: baseFloatingStyles } = useFloating({
    strategy: 'fixed',
    placement: 'right-start',
    transform: true,
    whileElementsMounted: (...args) => {
      // needed to keep the spiders aligned on scrolling
      return autoUpdate(...args, {
        animationFrame: true,
      });
    },
  });

  const floatingStyles = {
    zIndex: vars.zIndex.z001,
    ...baseFloatingStyles,
  };

  /** Generate refs for each option in the input array */
  const actionRefs: MutableRefObject<Array<RefObject<HTMLDivElement>>> = useRef(
    options?.map(() => React.createRef())
  );

  /** Adjust the refs array to match the length of inputs to maintain parity as options are added or removed from the inputs array */
  useEffect(() => {
    actionRefs.current = options.map(
      (_, i) => actionRefs.current[i] ?? React.createRef()
    );
  }, [options]);

  useEffect(() => {
    if (selectedValue !== IDEATE_OPTION.THIS_BUT) {
      handleThisButCancel();
    }
    if (selectedValue !== IDEATE_OPTION.EXPRESSIONS) {
      handleExpressionsClose();
    }
  }, [selectedValue]);

  const handleOpenMenu = () => {
    if (onOpen) {
      onOpen();
    }
    openSpider(group, id);
  };

  const handleCloseMenu = () => {
    if (onClose) {
      onClose();
    }
    closeSpider(group); // Close the current spider
  };

  const handleOpenChange = () => {
    setSelectedValue(null);
    if (!spiderOpen) {
      handleOpenMenu();
    } else {
      handleCloseMenu();
    }
  };

  /** Set selected spider option button  */
  const handleSelect = (value: string) => {
    setSelectedValue(value);
  };

  const handleThisButSelect = (value: string) => {
    setShowThisBut(true);
    setSelectedValue(value);
  };

  const handleThisButCancel = () => {
    setShowThisBut(false);
  };

  const buttonLabel = brainstormLabel || t('Brainstorm');

  const handleExpressionsSelect = (value: string) => {
    handleSelect(value);
    setShowExpressions(true);
  };
  const handleExpressionsClose = () => setShowExpressions(false);

  if (!options) {
    return null;
  }

  if (noOptions) {
    return (
      <BrainstormButton
        isHovered
        className={Styles.brainstorm}
        children={buttonLabel}
        onClick={onBrainstorm}
      />
    );
  }

  return (
    <div>
      <div ref={refs.setReference} onClick={handleOpenChange}>
        <BrainstormButton
          className={Styles.brainstorm}
          children={buttonLabel}
          menuOpen={spiderOpen}
          onCloseMenu={handleCloseMenu}
          ref={elementRef}
        />
      </div>
      {spiderOpen ? (
        <div ref={refs.setFloating} style={floatingStyles}>
          <div
            ref={containerRef}
            className={`${Styles.optionsContainer} ${className}`}
            data-tour="pdp-ideation-spider-menu"
          >
            {options.map((item, index) => (
              <div
                className={Styles.optionsItem}
                key={item.value}
                ref={actionRefs.current[index]}
              >
                <IdeationSpiderThread
                  open={spiderOpen}
                  containerRef={containerRef}
                  itemRef={elementRef}
                  actionRef={actionRefs.current[index]}
                />
                <Switch condition={item.value}>
                  <Switch.When when={IDEATE_OPTION.THIS_BUT}>
                    <div>
                      {!showThisBut ? (
                        <OptionButton
                          option={{
                            ...item,
                            selected: selectedValue === item.value,
                          }}
                          onSelect={handleThisButSelect}
                        />
                      ) : null}
                      {showThisBut ? (
                        <ThisButEditor
                          onCancel={handleThisButCancel}
                          minimized={thisButMinimized}
                          onSubmit={(thisButValue) => {
                            item.handler?.({ thisBut: thisButValue });
                            setThisButMinimized(true);
                          }}
                          thisButValue={thisButValue}
                          setThisButValue={setThisButValue}
                          setThisButMinimized={setThisButMinimized}
                        />
                      ) : null}
                    </div>
                  </Switch.When>
                  <Switch.When when={IDEATE_OPTION.EXPRESSIONS}>
                    <div>
                      {!showExpressions ? (
                        <OptionButton
                          option={{
                            ...item,
                            selected: selectedValue === item.value,
                          }}
                          onSelect={handleExpressionsSelect}
                        />
                      ) : null}
                      {showExpressions ? (
                        <ExpressionList
                          onClose={handleExpressionsClose}
                          onExpression={(expression) =>
                            item.handler?.({ emotion: expression })
                          }
                        />
                      ) : null}
                    </div>
                  </Switch.When>
                  <Switch.Default>
                    <OptionButton
                      option={{
                        ...item,
                        selected: selectedValue === item.value,
                      }}
                      onSelect={(value) => {
                        handleSelect(value);
                        item.handler?.();
                      }}
                    />
                  </Switch.Default>
                </Switch>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

Options.displayName = 'IdeationElementOptions';

type LoaderProps = {
  className?: string;
  label?: string;
};

export const Loader = (props: LoaderProps) => {
  const { className, label } = props;
  const { t } = useTranslation();
  const message = label || t('Preparing...');
  return (
    <Button
      radii="pill"
      variant="subtle"
      size="sm"
      className={clsx(Styles.loadingButton, className)}
    >
      <Spinner size="xs" className={Styles.loader} />
      {message}
    </Button>
  );
};
Loader.displayName = 'IdeationElementLoader';

export const Actions = Card.Actions;
