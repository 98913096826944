import {
  PropsWithChildren,
  useCallback,
  KeyboardEvent,
  forwardRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { vars } from '@lib/theme';
import { TextInput, Icons, IconButton, Text, Flex } from '@lib/ui';
import { UploadStatusType, TEMP_LOCAL_MEMBER } from '../../stores';
import * as Styles from './name-input.css';

const MAXIMUM_INPUT_LENGTH = 15;

type Props = PropsWithChildren & {
  id: string;
  name?: string;
  condensed?: boolean;
  hasError?: boolean;
  isOnboarding?: boolean;
  imageStatus: UploadStatusType;
  nameStatus: UploadStatusType;
  onConfirm: () => void;
  onChange: (value: string) => void;
  placeholder?: string;
  value?: string;
  isFilled: boolean;
  isNameDirty: boolean;
  setNameDirty: (value: boolean) => void;
  setIsTyping: (value: boolean) => void;
  textInputFocus: boolean;
  onClick: () => void;
};

export const NameInput = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { t } = useTranslation();

  const {
    id = TEMP_LOCAL_MEMBER,
    condensed = false,
    hasError = false,
    isOnboarding = false,
    imageStatus = 'empty',
    isFilled = false,
    nameStatus = 'empty',
    onChange,
    onConfirm,
    placeholder,
    value = '',
    isNameDirty = false,
    setNameDirty,
    setIsTyping,
    textInputFocus,
    onClick,
  } = props;

  const handleChange = useCallback(
    (input: string) => {
      setNameDirty(true);
      onChange(input);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange]
  );

  const handleClean = useCallback(() => {
    onChange('');
    setNameDirty(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange]);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') {
      return;
    }
    if (!hasError) {
      onConfirm();
      (event.target as HTMLInputElement).blur();
    }
  };

  const showNameInput =
    id !== TEMP_LOCAL_MEMBER ||
    imageStatus === 'uploaded' ||
    imageStatus === 'success';
  const isSuccess = nameStatus === 'success';
  const disabled = !isOnboarding && id === TEMP_LOCAL_MEMBER;

  const isNameInvalid = hasError && isNameDirty;
  const showNameCount = showNameInput && (textInputFocus || isNameInvalid);
  const shouldShowAdnornment = isNameDirty && textInputFocus;
  const isEmptyError = !isFilled && hasError && isNameDirty;
  const isFilledError = isFilled && hasError;

  return (
    <Flex
      ref={ref}
      flexDirection="column"
      className={Styles.containerVariants[condensed ? 'condensed' : 'normal']}
      gap={vars.scales.s04}
      onClick={onClick}
    >
      <TextInput.Root
        variant="light"
        className={clsx([
          Styles.nameInput,
          Styles.statusVariant[disabled ? 'disabled' : nameStatus],
          { [Styles.showNameInput]: !isOnboarding || showNameInput },
          { [Styles.condensedNameInput]: condensed },
          { [Styles.emptyError]: isEmptyError },
          { [Styles.filledError]: isFilledError },
        ])}
      >
        {isSuccess ? (
          <Flex
            justifyContent="center"
            alignItems="center"
            className={clsx(
              [Styles.successOverlay],
              Styles.containerVariants[condensed ? 'condensed' : 'normal'],
              Styles.successHeightVariant[condensed ? 'condensed' : 'normal']
            )}
          >
            <Icons.CheckmarkIcon color={vars.colors.green250} />
          </Flex>
        ) : null}
        <TextInput.Input
          onFocus={() => setIsTyping(true)}
          onBlur={() => setIsTyping(false)}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={value}
          placeholder={placeholder || ''}
          disabled={disabled}
        >
          {shouldShowAdnornment ? (
            <TextInput.Adornment align="end">
              <IconButton
                variant="light"
                className={Styles.deleteButton}
                icon={<Icons.CloseIcon aria-hidden />}
                iconSize="16"
                label={t('Clear input')}
                onClick={handleClean}
                size="xs"
              />
            </TextInput.Adornment>
          ) : null}
        </TextInput.Input>
      </TextInput.Root>
      {showNameCount ? (
        <Flex
          className={clsx(
            Styles.textCount,
            Styles.label[isNameInvalid ? 'error' : 'normal'],
            {
              [Styles.condensed]: condensed,
            }
          )}
        >
          {isNameInvalid ? <Icons.ExclamationCircleIcon /> : null}
          <Text size="12">
            {value.length}/{MAXIMUM_INPUT_LENGTH}
          </Text>
        </Flex>
      ) : null}
    </Flex>
  );
});
