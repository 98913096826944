import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Heading, Text, Dialog } from '@lib/ui';
import * as Styles from './mobile-overlay.css';

interface MobileOverlayProps {
  open: boolean;
  onDismiss: () => void;
}

export function MobileOverlay(props: MobileOverlayProps) {
  const [open, setOpen] = useState(props.open);
  const { t } = useTranslation();
  const description =
    'Spotter Studio does not currently support mobile devices. Please login on your desktop.';

  const handleDismiss = () => {
    setOpen(false);
    props.onDismiss();
  };

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className={Styles.overlay} />
        <Dialog.Content
          className={Styles.content}
          variant="dark"
          description={t(description)}
        >
          <Trans t={t}>
            <Dialog.Title asChild>
              <Heading>We're sorry</Heading>
            </Dialog.Title>
            <Text size="14">{description}</Text>
          </Trans>
          <Dialog.Footer>
            <Dialog.CTA
              autoFocus
              fill="ghost"
              label={t('I understand, proceed anyway')}
              onClick={handleDismiss}
            />
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
