import { ChangeEvent, forwardRef, ReactNode, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  Button,
  Dialog,
  IconButton,
  Icons,
  Image as AsyncImage,
  Spinner,
  type ImageProps,
} from '@lib/ui';
import * as Styles from './thumbnail.css';

/**
 * Thumbnail Component
 */

type ThumbnailProps = {
  children?: ReactNode;
  className?: string;
  id?: string;
  isLoading?: boolean;
  src?: string;
};

export const Root = forwardRef(
  (
    { className, children, id, isLoading, src, ...props }: ThumbnailProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <div
        key={id}
        id={id}
        ref={ref}
        {...props}
        className={clsx(Styles.root, className)}
      >
        {isLoading ? <Spinner className={Styles.spinner} size="md" /> : null}
        {children}
      </div>
    );
  }
);
Root.displayName = 'ThumbnailRoot';

type ImageViewerProps = Dialog.TriggerProps & {
  children?: ReactNode;
  className?: string;
  icon?: ReactNode;
  onOpen?: () => void;
  src?: string;
};

export const ImageViewer = ({
  children,
  className,
  icon,
  onOpen,
  src,
  ...props
}: ImageViewerProps) => {
  const { t } = useTranslation();

  return (
    <Dialog.Root>
      <Dialog.Trigger
        asChild={!!children}
        className={clsx(Styles.container, className)}
        {...props}
        onClick={onOpen ? onOpen : undefined}
      >
        <>
          {icon ? <span className={Styles.iconContainer}>{icon}</span> : null}
          {children}
        </>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className={Styles.overlay} />
        <Dialog.Content className={Styles.viewerContainer}>
          <Dialog.Title />
          <AsyncImage aspectRatio="16/9" src={src} />
          <Dialog.CloseTrigger className={Styles.closeContainer}>
            <IconButton
              label={t('Close Image Viewer')}
              icon={<Icons.MinimizeIcon aria-hidden />}
              radii="pill"
              size="lg"
              className={Styles.closeButton}
            />
          </Dialog.CloseTrigger>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
ImageViewer.displayName = 'ThumbnailImageViewer';

export const Image = ({ className, src, ...props }: ImageProps) => {
  if (!src?.length) {
    return null;
  }

  return (
    <div className={Styles.thumbnail}>
      <AsyncImage
        className={className}
        aspectRatio="16/9"
        src={src}
        {...props}
      />
    </div>
  );
};
Image.displayName = 'ThumbnailImage';

export const Stack = ({ className, src, ...props }: ImageProps) => {
  if (!src?.length) {
    return null;
  }

  return (
    <div className={Styles.stackContainer}>
      <AsyncImage
        className={Styles.stackThumb}
        aspectRatio="16/9"
        src={src}
        {...props}
      />
    </div>
  );
};
Stack.displayName = 'ThumbnailStack';

type ImageUploadCardProps = {
  className?: string;
  label?: string;
  onClick?: () => void;
};

export const ImageUploadCard = ({
  className,
  label,
  onClick,
  ...props
}: ImageUploadCardProps) => {
  const { t } = useTranslation();
  if (!onClick) return null;
  const uploadLabel = label || t('Upload image');
  return (
    <Button
      fill="none"
      variant="subtle"
      className={Styles.uploader}
      onClick={onClick}
      {...props}
    >
      <Icons.UploadIcon aria-hidden /> {uploadLabel}
    </Button>
  );
};
ImageUploadCard.displayName = 'ThumbnailImageUploadCard';

type ImageUploadCardLabeledProps = ImageUploadCardProps & {
  cta?: string;
};

export const ImageUploadCardLabeled = ({
  className,
  cta,
  label,
  onClick,
  ...props
}: ImageUploadCardLabeledProps) => {
  const { t } = useTranslation();
  if (!onClick) return null;
  const ctaLabel = cta || t('Already have an idea?');
  const buttonLabel = label || t('Upload');
  return (
    <Button
      fill="none"
      variant="subtle"
      className={Styles.uploaderLabeled}
      onClick={onClick}
      {...props}
    >
      {ctaLabel}{' '}
      <span className={Styles.buttonContainer}>
        <Icons.UploadIcon aria-hidden className={Styles.uploadIcon} />{' '}
        {buttonLabel}
      </span>
    </Button>
  );
};
ImageUploadCard.displayName = 'ThumbnailImageUploadCard';

type ImageUploadTileProps = {
  className?: string;
  icon?: ReactNode;
  onClick?: () => void;
};

export const ImageUploadTile = ({
  className,
  icon,
  onClick,
  ...props
}: ImageUploadTileProps) => {
  const { t } = useTranslation();
  const buttonIcon = icon || <Icons.UploadIcon aria-hidden />;
  return (
    <div className={Styles.uploaderTile} {...props}>
      {onClick ? (
        <IconButton
          label={t('Upload')}
          className={Styles.uploaderButton}
          icon={buttonIcon}
          onClick={onClick}
          iconSize="64"
          fill="none"
        />
      ) : null}
    </div>
  );
};
ImageUploadTile.displayName = 'ThumbnailImageUploadTile';

type ImageUploaderProps = {
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const ImageUploader = forwardRef(
  (
    { className, onChange, ...props }: ImageUploaderProps,
    ref: Ref<HTMLInputElement>
  ) => {
    return (
      <input
        ref={ref}
        className={className}
        accept="image/*"
        onChange={onChange}
        required={false}
        style={{ display: 'none' }}
        type="file"
        {...props}
      />
    );
  }
);
ImageUploader.displayName = 'ThumbnailImageUploader';
