'use client';

import { useEffect } from 'react';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';

interface UserData {
  email: string;
  id: string;
  name: string;
  [id: string]: string;
}

const defaultRumInitConfig = {
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  trackSessionAcrossSubdomains: true,
} as Omit<RumInitConfiguration, 'clientToken' | 'site'>;

const defaultLogsInitConfig = {
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  trackSessionAcrossSubdomains: true,
} as Omit<LogsInitConfiguration, 'clientToken' | 'site'>;

type CommonConfiguration = 'clientToken' | 'env' | 'site' | 'version';
type MatchOption = string | RegExp | ((value: string) => boolean);

export function Datadog({
  clientToken,
  isEnabled = true,
  site,
  env,
  logsConfig,
  tracingUrls,
  rumInitConfig,
  userData,
  version,
}: {
  clientToken: string;
  isEnabled?: boolean;
  site: string;
  env: string;
  rumInitConfig?: Omit<RumInitConfiguration, CommonConfiguration>;
  logsConfig?: Omit<LogsInitConfiguration, CommonConfiguration>;
  /**
   * Specify API URLs to propagate trace headers for connection between RUM and backend trace
   */
  tracingUrls?: MatchOption;
  userData?: UserData;
  version: string;
}) {
  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    const context = datadogRum.getInternalContext();

    // If Datadog not initialized...
    if (!context) {
      // Initialize Logs
      datadogLogs.init({
        clientToken,
        env,
        site,
        version,
        ...defaultLogsInitConfig,
        ...logsConfig,
      });

      // Initialize Real User Monitoring (RUM)
      datadogRum.init({
        clientToken,
        env,
        site,
        version,
        allowedTracingUrls: [
          {
            match: tracingUrls ?? '',
            propagatorTypes: ['tracecontext', 'datadog'],
          },
        ],
        ...defaultRumInitConfig,
        ...rumInitConfig,
      });

      // Add user profile data
      if (userData) {
        datadogRum.setUser(userData);
      }
    }
  }, []);

  // Nothing to render
  return null;
}
