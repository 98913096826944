import { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import { Button, Flex, Icons, Tooltip } from '@lib/ui';
import * as Styles from './breadcrumbs.css';
import { useBreadcrumbs } from './useBreadcrumbs';

/**
 * Breadcrumbs Root
 */
export type RootProps = PropsWithChildren & {
  className?: string;
  separator?: ReactNode;
};

const Root = ({
  children,
  className,
  separator = <Icons.ChevronRightIcon aria-hidden />,
}: RootProps) => {
  const childrenWithSeparator = useBreadcrumbs({ children, separator });

  return (
    <Flex
      gap="4px"
      alignItems="center"
      className={clsx(Styles.root, className)}
    >
      {childrenWithSeparator}
    </Flex>
  );
};

Root.displayName = 'BreadcrumbsRoot';

/**
 * Breadcrumbs Link
 */
export type LinkProps = PropsWithChildren & {
  className?: string;
  onClick?: () => void;
  truncation?: boolean;
};
const Link = ({
  children,
  className,
  onClick,
  truncation,
  ...rest
}: LinkProps) => {
  const props = {
    className: clsx(className, Styles.link),
    children,
    ...rest,
  };

  const truncatedName =
    typeof children === 'string' ? `${children.slice(0, 18)}...` : children;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return truncation ? (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={0}>
        <Tooltip.Trigger asChild>
          <Button
            fill="none"
            size="sm"
            variant="light"
            onClick={handleClick}
            disabled={!onClick}
            className={props.className}
          >
            {truncatedName}
          </Button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="bottom"
            variant="dark"
            sideOffset={8}
            align="center"
          >
            {props.children}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  ) : (
    <Button
      fill="none"
      size="sm"
      variant="light"
      onClick={handleClick}
      disabled={!onClick}
      className={props.className}
    >
      {props.children}
    </Button>
  );
};

Link.displayName = 'BreadcrumbsLink';

export { Root, Link };
