import { getLabsUrl } from '@studio/utils/get-labs-url';
import { ApiService } from '@lib/api-service';

class Api {
  connect: ApiService;
  bowser: ApiService;
  relayRaptor: ApiService;
  lakitu: ApiService;
  auth0: ApiService;
  boo: ApiService;
  kirby: ApiService;
  labs: ApiService;
  dataApi: ApiService;

  // prettier-ignore
  constructor() {
    this.connect = new ApiService(import.meta.env.VITE_CONNECT_API_URL);
    this.bowser = new ApiService(import.meta.env.VITE_BOWSER_URL);
    this.relayRaptor = new ApiService(import.meta.env.VITE_RELAY_RAPTOR_API_URL);
    this.lakitu = new ApiService(import.meta.env.VITE_BACKEND_API_URL);
    this.auth0 = new ApiService(import.meta.env.VITE_AUTH0_API_URL);
    this.boo = new ApiService(import.meta.env.VITE_BOO_URL);
    this.kirby = new ApiService(import.meta.env.VITE_KIRBY_ORIGIN);
    this.labs = new ApiService(getLabsUrl());
    this.dataApi = new ApiService(import.meta.env.VITE_DATA_API_URL);
  }
}

export const api = new Api();
