import { PowerKeyword } from '@lib/types';
import { getPowerKeywordTooltipVariant } from '@lib/ui';

export const getPowerKeywordTrackingData = (
  keyword: PowerKeyword,
  content: string | undefined
): Record<string, string> => {
  const {
    id: keywordId,
    snapshotsTopPerforming,
    nGram,
    last6SnapshotOverperforming,
    superOverperforming,
    num_categories: numCategories,
    top_categories: topCategories,
    ...rest
  } = keyword.data;

  const variant = getPowerKeywordTooltipVariant(rest.performanceRatio);

  return {
    fullText: String(content),
    keywordId: String(keywordId),
    variant,
    nGram: String(nGram),
    snapshotsTopPerforming: String(snapshotsTopPerforming),
    ...rest,
    keyword: keyword.content,
    startPos: String(keyword.startPos),
    startWordPos: String(keyword.startWordPos),
    last6SnapshotOverperforming: String(last6SnapshotOverperforming),
    superOverperforming: String(superOverperforming),
    num_categories: String(numCategories),
    top_categories: topCategories?.join(', ') || '',
  };
};
