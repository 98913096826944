import { getLocalStorage } from '@studio/utils';
import { vars } from '@lib/theme';
import {
  ImageStepContent,
  StepContent,
  StepContentType,
  VideoStepContent,
} from './components';
import {
  SEEN_BRAINSTORM_TOUR_LEGACY_LS_KEY,
  SEEN_TOURS_LS_KEY,
} from './constants';
import { type Tours } from './tours';

export const hasUserSeenTour = (tour: keyof Tours) => {
  const { getItem, removeItem } = getLocalStorage();
  const toursSeen = getItem<Tours>(SEEN_TOURS_LS_KEY);

  // account for user having seen the brainstorm tour in its first iteration
  if (tour === 'brainstorm' && !toursSeen?.brainstorm) {
    const hasSeenLegacyBrainstorm =
      getItem(SEEN_BRAINSTORM_TOUR_LEGACY_LS_KEY) === true;
    if (hasSeenLegacyBrainstorm) {
      removeItem(SEEN_BRAINSTORM_TOUR_LEGACY_LS_KEY);
      setTourAsSeen('brainstorm');
      return true;
    }
  }

  return !!toursSeen?.[tour];
};

export const setTourAsSeen = (tour: keyof Tours) => {
  const { getItem, setItem } = getLocalStorage();
  const toursSeen = getItem<Tours>(SEEN_TOURS_LS_KEY);
  setItem<Tours>(SEEN_TOURS_LS_KEY, {
    ...(toursSeen ?? {}),
    [tour]: true,
  });
};

export const makeSelector = (id: string) => `[data-tour="${id}"]`;

export const isStepTakeover = (content: StepContentType) =>
  content.type === 'takeover';

export const isStepVideo = (content: StepContentType) => !!content?.videoSrc;

export const isStepImage = (content: StepContentType) => !!content?.imageSrc;

export const getContentComponent = (content: StepContentType) => {
  if (isStepVideo(content)) {
    return VideoStepContent;
  }

  if (isStepImage(content)) {
    return ImageStepContent;
  }

  return StepContent;
};

const oppositeSide = {
  top: 'bottom',
  bottom: 'top',
  right: 'left',
  left: 'right',
};

type Alignment = 'top' | 'bottom' | 'left' | 'right';

export type PopoverState = {
  position?: Alignment & 'custom';
  verticalAlign: Alignment;
  horizontalAlign: Alignment;
  helperRect: DOMRect;
  targetRect: DOMRect & {
    top: number;
    right: number;
    bottom: number;
    left: number;
    windowWidth: number;
    windowHeight: number;
  };
};

/**
 * Returns styles that position the popover centered relative to the target.
 * Also positions the arrow so it points correctly.
 *
 * Based on https://codesandbox.io/p/sandbox/compassionate-meadow-qkw7hv?file=%2FApp.js%3A15%2C44
 * but made safe for TS.
 */
export function getPopoverPosition(state: PopoverState) {
  const { position, verticalAlign, horizontalAlign, helperRect, targetRect } =
    state;

  if (!position || position === 'custom') {
    return {};
  }

  if (position === 'center') {
    const x = (targetRect.windowWidth - helperRect.width) / 2;
    const y = (targetRect.windowHeight - helperRect.height) / 2;
    return {
      transform: `translate(${x}px, ${y}px)`,
    };
  }

  const arrowWidth = 16;
  const arrowHeight = 12;
  const color = vars.colors.gray750;
  const isVertical = position === 'top' || position === 'bottom';
  const spaceFromSide = isVertical
    ? helperRect.width / 2
    : helperRect.height / 2;

  const horizontalCompensation =
    helperRect.width > targetRect.width
      ? (helperRect.width - targetRect.width) / -2
      : targetRect.width / 2 - helperRect.width / 2;

  const verticalCompensation =
    helperRect.height > targetRect.height
      ? (helperRect.height - targetRect.height) / -2
      : targetRect.height / 2 - helperRect.height / 2;

  const coords = {
    top: [
      targetRect.x + horizontalCompensation,
      targetRect.top - helperRect.height - arrowHeight * 1.5,
    ],
    right: [targetRect.right, targetRect.y + verticalCompensation],
    bottom: [
      targetRect.x + horizontalCompensation,
      targetRect.bottom + arrowHeight * 1.5,
    ],
    left: [
      targetRect.left - arrowWidth * 1.5 - helperRect.width,
      targetRect.y + verticalCompensation,
    ],
  };

  // prettier-ignore
  return {
    [`--rtp-arrow-${isVertical ? oppositeSide[horizontalAlign] : verticalAlign}`]: spaceFromSide - arrowHeight / 2 + 'px',
    [`--rtp-arrow-${oppositeSide[position]}`]: -arrowHeight + 2 + 'px',
    [`--rtp-arrow-border-${isVertical ? 'left' : 'top'}`]: `${arrowWidth / 2}px solid transparent`,
    [`--rtp-arrow-border-${isVertical ? 'right' : 'bottom'}`]: `${arrowWidth / 2}px solid transparent`,
    [`--rtp-arrow-border-${position}`]: `${arrowHeight}px solid ${color}`,
    transform: `translate(${Math.round(coords[position][0])}px, ${Math.round(coords[position][1])}px)`,
  };
}
