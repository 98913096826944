import { PowerKeyword } from '@lib/types';
import {
  getPowerKeywordTooltipVariant,
  PowerKeywordTooltipVariant,
} from '@lib/ui';

export const getPowerKeywordListTrackingData = (
  powerKeywords?: PowerKeyword[]
) => {
  if (!powerKeywords) {
    return null;
  }

  let strong_pk_count = 0;
  let super_pk_count = 0;
  let explosive_pk_count = 0;

  const powerkeywordContents = powerKeywords.map((keyword) => {
    const variant = getPowerKeywordTooltipVariant(
      keyword.data.performanceRatio
    );

    switch (variant) {
      case PowerKeywordTooltipVariant.STRONG:
        strong_pk_count++;
        break;
      case PowerKeywordTooltipVariant.SUPER:
        super_pk_count++;
        break;
      case PowerKeywordTooltipVariant.EXPLOSIVE:
        explosive_pk_count++;
        break;
    }

    return keyword.content;
  });

  return {
    powerkeywords: powerkeywordContents.join(', '),
    strong_pk_count: String(strong_pk_count),
    super_pk_count: String(super_pk_count),
    explosive_pk_count: String(explosive_pk_count),
  };
};
