import {
  forwardRef,
  type ForwardedRef,
  type ElementType,
  type ButtonHTMLAttributes,
  type ReactNode,
  type ReactElement,
  type HTMLAttributeAnchorTarget,
} from 'react';
import clsx from 'clsx';
import * as Styles from './button.css';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  Styles.ButtonVariants & {
    adornmentEnd?: ReactElement;
    adornmentStart?: ReactElement;
    as?: ElementType;
    children?: ReactNode;
    disabled?: boolean;
    fullWidth?: boolean;
    href?: string;
    loading?: boolean;
    sheer?: boolean;
    type?: 'button' | 'submit' | 'reset';
    target?: HTMLAttributeAnchorTarget;
  };

const Button = forwardRef(
  (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      adornmentEnd = null,
      adornmentStart = null,
      align,
      as: Component = 'button',
      children,
      className,
      disabled,
      fill = 'solid',
      fullWidth,
      gap,
      loading,
      radii,
      size = 'md',
      textColor,
      type = 'button',
      variant = 'primary',
      ...rest
    } = props;

    const classes = clsx(
      Styles.buttonVariants({
        align,
        fill,
        fullWidth,
        gap,
        radii,
        size,
        textColor,
        variant,
      }),
      disabled && 'disabled',
      loading && 'loading',
      className
    );

    return (
      <Component ref={ref} type={type} className={classes} {...rest}>
        {adornmentStart && (
          <span className={Styles.adornment}>{adornmentStart}</span>
        )}
        {children}
        {adornmentEnd && (
          <span className={Styles.adornment}>{adornmentEnd}</span>
        )}
      </Component>
    );
  }
);

Button.displayName = 'Button';

export { Button };
