import { PresignedS3UploadRes } from '@lib/types';

export const CHANNEL_ROLE = {
  USER: 'user',
  ADMIN: 'admin',
  NONE: 'none',
  REMOVE: 'remove',
  PRIMARY: 'primary',
} as const;

export type ChannelRole = (typeof CHANNEL_ROLE)[keyof typeof CHANNEL_ROLE];

export type UserEntitlement = {
  channelId: string;
  role: ChannelRole;
};
export type ManageUserInput = {
  organizationId: string;
  orgUserId: string;
  entitlements: UserEntitlement[];
};

export const TEMP_LOCAL_MEMBER = 'temp_local_member';

export type CastMember = {
  id: string;
  name?: string;
  image?: string | null;
  isPrimary: boolean;
  channelUCID?: string;
};

export type LocalCastMember = CastMember & {
  contentType?: string;
  imageStatus?: UploadStatusType | 'empty';
  nameStatus?: UploadStatusType | 'empty';
};

export type UploadStatusType =
  | 'empty'
  | 'loading'
  | 'uploaded'
  | 'error'
  | 'success';

interface Page {
  current: number;
  next: number | null;
  previous: number | null;
  total: number;
}
export interface CastMembersResponseData {
  results: CastMember[];
  page: Page;
  totalRecords: number;
}

export type CreateCastMemberResponse = {
  upload: PresignedS3UploadRes;
  castMember: CastMember;
};
