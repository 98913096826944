import { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useStore } from 'zustand';
import { ProjectStoreContext, SetRoomProvider } from '@studio/providers';
import { VideoProjectPostInput, VideoProjectPostResponse } from '@studio/types';
import { PROJECT_STATUS, ProjectsResponseItem } from '@lib/types';
import { Toast, Icons } from '@lib/ui';
import { createProject } from '../requests';

const convertInputToProjectResponseItem = (
  input: VideoProjectPostInput,
  response: VideoProjectPostResponse
): ProjectsResponseItem => {
  const concept = input.concept?.options?.find((option) => {
    return option.id === input.concept?.primary;
  })?.content;
  const title = input.title?.options?.find((option) => {
    return option.id === input.title?.primary;
  })?.content;
  const thumbnail = input.thumbnail?.options?.find((option) => {
    return option.id === input.thumbnail?.primary;
  })?.content;

  const name = input.meta?.name;
  const isPublic = input.meta?.isPublic;
  return {
    id: response.id,
    title: title || undefined,
    thumbnail: thumbnail || undefined,
    concept: concept || undefined,
    isPublic: !!isPublic,
    name: name || undefined,
    collaborators: [],
    createdAt: new Date(response.createdAt),
    channelUCID: input.channelId,
    sponsors: [],
    tags: [],
    videoProjectId: response.id,
    status: input.meta?.status || PROJECT_STATUS.IDEA,
    updatedAt: new Date(response.createdAt),
    createdBy: '',
  };
};

// This can only be used within the project page
export function useCreateProjectMutation() {
  const queryClient = useQueryClient();
  const { setRoomId } = useContext(SetRoomProvider);
  const { projectStore } = useContext(ProjectStoreContext);
  const { updateId } = useStore(projectStore);
  const { toast } = Toast.useToast();

  return useMutation({
    mutationKey: ['create-project'],
    mutationFn: (input: VideoProjectPostInput) => createProject({ input }),
    onSuccess(response, variables) {
      updateId(response.videoProjectId);
      setRoomId(response.id);
      toast({
        icon: <Icons.CheckmarkIcon />,
        message: t('Project created!'),
      });
      queryClient.setQueriesData<ProjectsResponseItem[] | undefined>(
        { queryKey: ['projects', variables.channelId] },
        (oldData) => {
          const newProject = convertInputToProjectResponseItem(
            variables,
            response
          );

          if (!oldData) {
            return [];
          }

          if (!Array.isArray(oldData)) {
            return [newProject];
          }

          return [...oldData, newProject];
        }
      );
    },
    onError: (err) => {
      console.error('Failed to create project. Please try again later.', err);
      toast({
        message: t('Failed to create project. Please try again later.'),
      });
    },
  });
}
